import React from 'react';
import gql from 'graphql-tag';
import { Router, Redirect, RouteComponentProps } from '@reach/router';

import Code404 from 'components/Errors/Code404';
import AnalyticsContext from 'components/AnalyticsContext';

import { withAuth, InjectedAuthProps } from 'providers/Auth';

import Login from 'pages/Entry/Login/Login';
import ForceLogout from 'components/ForceLogout';

import Overlay from 'pages/Overlay/Overlay';

import { useIdentityQuery } from 'types/graphql';
import Home from 'pages/Home/Home';
import Dealers from 'pages/Dealers/Dealers';
import Users from 'pages/Users/Users';

gql`
  query Identity {
    currentDistributor {
      id
      isDistributor
      isAdmin
    }
  }
`;

interface RouteProps {
  path: string;
  Component: React.ComponentType<RouteComponentProps<{}>>;
  forceLogout?: boolean;
}

type AppRoute = (props: {
  path: string;
  Component: React.ComponentType<RouteComponentProps<{}>>;
  exact?: boolean;
  forceLogout?: boolean;
}) => JSX.Element;

type AppProps = InjectedAuthProps;

const App: React.FC<AppProps> = ({ authed }) => {
  const { data, loading } = useIdentityQuery();

  if (loading) {
    // Wait for identity to resolve before deciding what to show
    return null;
  }

  const isAdmin = data ? data.currentDistributor.isAdmin : false;

  const loggedInRoutes: RouteProps[] = [
    {
      path: '/',
      Component: Home,
    },
    {
      path: '/dealers',
      Component: Dealers,
    },
    {
      path: '/users',
      Component: Users,
    },
  ];

  const adminRoutes: RouteProps[] = isAdmin ? [] : [];

  const loggedOutRoutes: RouteProps[] = [
    {
      path: 'login',
      Component: Login,
    },
  ];

  if (!authed) {
    return (
      <Router>
        {/* Public */}
        {loggedOutRoutes.map(({ Component: Component, path }) => {
          return <Component path={path} key={path} />;
        })}
        <Redirect from="/" to="/login" default noThrow />
      </Router>
    );
  } else {
    return (
      <Overlay>
        <AnalyticsContext />

        <Router>
          {/* Private */}
          {loggedInRoutes.map(({ Component, path }) => (
            <Component path={path} key={path} />
          ))}

          {/* Admin */}
          {adminRoutes.map(({ Component, path }) => (
            <Component path={path} key={path} />
          ))}

          {/* If someone goes to activate while logged in, log them out */}
          {loggedOutRoutes.map(({ path, forceLogout }) =>
            forceLogout ? (
              <ForceLogout path={path} key={path} />
            ) : (
              <RedirectComponent path={path} to="/" key={path} />
            )
          )}

          {/* 404 */}
          <Code404 default />
        </Router>
      </Overlay>
    );
  }
};

const RedirectComponent: React.FC<RouteComponentProps<{
  to: string;
}>> = (props) => <Redirect to={props.to} noThrow />;

export default withAuth(App);
