import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';

import GenericError from 'components/Errors/GenericError';
import Loading from 'components/Loading/Loading';

import { AllDistributorsQuery } from 'types/graphql';

import { colors } from 'services/theme';
import CustomModal from 'components/CustomModal';
import EditDistributorModalContent from './components/EditDistributorModalContent';

export const ALL_DISTRIBUTORS = gql`
  query AllDistributors {
    allDistributors {
      id
      email
      phone
      isRep
      isDistributor
      name {
        first
        last
      }
      reps {
        id
      }
      customers {
        id
        repId
      }
    }
  }
`;

type Row = {
  name: string;
  id: string;
  email: string;
  phone: string;
  customers: number;
  role: string;
};

const useStyles = makeStyles({
  outer: {
    padding: 24,
    backgroundColor: colors.background.light,
  },
  table: {
    minWidth: 650,
  },
});

const Dealers: React.FC = () => {
  const classes = useStyles();
  const [selectedRow, setSelectedRow] = React.useState<Row>();
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  // this will clear out the snackbar
  // auto-hide not working when success persisted as true
  React.useEffect(() => {
    const timer = setTimeout(() => setSuccess(false), 6000);
    return () => {
      clearTimeout(timer);
    };
  });

  const { data, loading, error } = useQuery(ALL_DISTRIBUTORS);
  if (loading) return <Loading />;
  if (error) return <GenericError />;
  if (!data) return <div>No data... </div>;

  const { allDistributors } = data;

  const createData = (
    name: string,
    id: string,
    email: string,
    phone: string,
    customers: number,
    role: string
  ) => {
    return { name, id, email, phone, customers, role };
  };

  const rows: Row[] = [];

  allDistributors.map(
    (distributor: AllDistributorsQuery['allDistributors'][0]) => {
      const distributorFirstName =
        distributor.name && distributor.name.first
          ? distributor.name.first
          : '';
      const distributorLastName =
        distributor.name && distributor.name.last ? distributor.name.last : '';
      const distributorName =
        distributorFirstName.toString() + ' ' + distributorLastName.toString();
      const distributorId = distributor.id;
      const distributorEmail = distributor.email ? distributor.email : '';
      const distributorPhone = '';
      const distributorRole = distributor.isDistributor ? 'Distributor' : 'Rep';
      const distributorCustomerQuantity = distributor.customers
        ? distributor.customers.length
        : 0;

      rows.push(
        createData(
          distributorName,
          distributorId,
          distributorEmail,
          distributorPhone,
          distributorCustomerQuantity,
          distributorRole
        )
      );
    }
  );

  const handleEdit = (row: Row) => {
    setSelectedRow(row);
    setOpenEditModal(true);
  };

  return (
    <TableContainer className={classes.outer}>
      <div style={{ paddingBottom: 16 }}>
        <Chip
          color="primary"
          label={`Total Dealers: ${allDistributors.length}`}
        />
      </div>
      <Table
        component={Paper}
        className={classes.table}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Dealer</TableCell>
            <TableCell align="right">ID</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Phone</TableCell>
            <TableCell align="right">Customers</TableCell>
            <TableCell align="right">Role</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right">{row.email}</TableCell>
              <TableCell align="right">{row.phone}</TableCell>
              <TableCell align="right">{row.customers}</TableCell>
              <TableCell align="right">{row.role}</TableCell>
              {/* Actions */}
              <TableCell align="right">
                <Button onClick={() => handleEdit(row)}>Edit</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <CustomModal
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        title="Edit Distributor"
        addCloseButton={true}
      >
        {selectedRow && (
          <EditDistributorModalContent
            dealerId={selectedRow.id}
            setOpenEditModal={setOpenEditModal}
            setSuccess={setSuccess}
          />
        )}
      </CustomModal>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success">Successfully deleted dealer</Alert>
      </Snackbar>
    </TableContainer>
  );
};

export default Dealers;
