export const colors = {
  primary: '#226f54',
  primaryDark: '#195C43',
  primaryLight: '#87c38f',
  primaryExtraLight: '#E4EEEA',
  text: '#222222',
  textLight: '#555555',
  textDisabled: '#d1d2d3',
  darkGrey: '#808080',
  grey: '#e6e6e6',
  lightGrey: '#F5F5F5',
  backgroundGrey: '#FDFDFD',
  error: '#C00000',
  errorLight: '#ED7B7B',
  background: {
    light: 'E6EEEA',
  },
};

enum FontWeights {
  light = 300,
  regular = 400,
  bold = 700,
  black = 900,
}

enum FontSizes {
  title = '24px',
  subtitle = '20px',
  body = '16px',
  caption = '13px',
}

export enum FontClasses {
  title = 'title',
  subtitle = 'subtitle',
  body = 'body',
  caption = 'caption',
}

export const weights = FontWeights;
export const fontSizes = FontSizes;

export type ThemeType = typeof theme;

export const theme = {
  weights,
  fontSizes,
  colors,
  // In styled component this will be set dynamically
  isMobile: false,
};
