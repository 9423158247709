import * as React from 'react';

const logo = require('./logo.png');

const Home: React.FC = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${logo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'auto',
        height: '100vh',
        width: '100%',
      }}
    >
      <div style={{ paddingTop: 128, textAlign: 'center' }}>
        Trellis Admin Portal
      </div>
    </div>
  );
};

export default Home;
