import * as React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { resetPassword } from 'services/auth';

interface ResetDialogProps {
  initialEmail: string;
  open: boolean;
  handleRequestClose: () => void;
}

interface ResetDialogState {
  email: {
    text: string;
    hasError: boolean;
    errorMessage: string;
  };
  success: boolean;
}

export default class ResetDialog extends React.Component<
  ResetDialogProps,
  ResetDialogState
> {
  emailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  reset: () => void;
  constructor(props: ResetDialogProps) {
    super(props);

    this.state = {
      email: {
        text: '',
        hasError: false,
        errorMessage: '',
      },
      success: false,
    };

    this.emailChange = event => {
      this.setState({
        email: {
          text: event.target.value,
          hasError: false,
          errorMessage: '',
        },
      });
    };

    this.reset = () => {
      const email = this.state.email.text;
      resetPassword(email)
        .then(() => {
          // success
          this.setState({
            success: true,
          });
        })
        .catch(err => {
          switch (err.code) {
            case 'auth/invalid-email':
              this.setState({
                email: {
                  text: email,
                  hasError: true,
                  errorMessage: 'Invalid email address',
                },
              });
              break;
            case 'auth/user-not-found':
              this.setState({
                email: {
                  text: email,
                  hasError: true,
                  errorMessage: 'Account does not exist',
                },
              });
              break;
            default:
              console.error('Unknown error: ', err);
              break;
          }
        });
    };
  }

  UNSAFE_componentWillReceiveProps() {
    if (!this.props.open) {
      const { initialEmail } = this.props;
      this.setState({
        email: {
          text: initialEmail,
          hasError: false,
          errorMessage: '',
        },
        success: false,
      });
    }
  }

  render() {
    const { handleRequestClose, open } = this.props;
    return (
      <Dialog open={open} onClose={handleRequestClose} fullWidth>
        <DialogTitle>Reset Password</DialogTitle>
        {this.state.success
          ? [
              <DialogContent key={0}>
                <DialogContentText>
                  We sent instructions to change your password to{' '}
                  {this.state.email.text}, please check both your inbox and spam
                  folder.
                </DialogContentText>
              </DialogContent>,
              <DialogActions key={1}>
                <Button onClick={handleRequestClose} color="primary">
                  Cancel
                </Button>
              </DialogActions>,
            ]
          : [
              <DialogContent key={0}>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Email Address"
                  type="email"
                  error={this.state.email.hasError}
                  helperText={this.state.email.errorMessage}
                  onChange={this.emailChange}
                  value={this.state.email.text}
                  autoComplete="email"
                  onKeyDown={e => (e.keyCode === 13 ? this.reset() : null)}
                />
              </DialogContent>,
              <DialogActions key={1}>
                <Button onClick={this.reset} color="primary">
                  Reset
                </Button>
                <Button onClick={handleRequestClose}>Cancel</Button>
              </DialogActions>,
            ]}
      </Dialog>
    );
  }
}
