/* eslint-disable graphql/template-strings */
import * as React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { colors } from 'services/theme';
import { ALL_USERS } from '../Users';

// const DELETE_USER = gql`
//   mutation DeleteUser($input: DeleteUserInput!) {
//     deleteUser(input: $input) {
//       id
//     }
//   }
// `;

const useStyles = makeStyles({
  redButton: {
    backgroundColor: colors.errorLight,
  },
});

type EditUserModalProps = {
  dealerId: string;
  setOpenEditModal: (openEditModal: boolean) => void;
  setSuccess: (success: boolean) => void;
};

const EditUserModalContent: React.FC<EditUserModalProps> = (props) => {
  const classes = useStyles();

  const { dealerId, setOpenEditModal, setSuccess } = props;

  // const [deleteDealer, { loading, error }] = useMutation(DELETE_USER, {
  //   refetchQueries: [{ query: ALL_USERS }],
  // });

  return (
    <>
      <div>
        <b>Dealer ID:</b> {dealerId}
      </div>

      <div style={{ paddingTop: 16, paddingBottom: 16 }}>
        Are you sure you want to delete this dealer?
      </div>

      {/* {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: 24,
          }}
        >
          <div style={{ paddingRight: 16 }}>Removing dealer...</div>
          <CircularProgress />
        </div>
      ) : null} */}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <Button
          className={classes.redButton}
          variant="contained"
          // onClick={() =>
          //   deleteDealer({
          //     variables: {
          //       input: {
          //         dealerId: dealerId,
          //       },
          //     },
          //   })
          //     .then(() => setSuccess(true))
          //     .then(() => setOpenEditModal(false))
          // }
        >
          Delete
        </Button>

        <Button
          color="secondary"
          variant="outlined"
          onClick={() => setOpenEditModal(false)}
        >
          Cancel
        </Button>
      </div>

      {/* {error && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="error">{error.message.split(':')[1]}</Alert>
        </Snackbar>
      )} */}
    </>
  );
};

export default EditUserModalContent;
