/* THIS IS A GENERATED FILE, DO NOT EDIT DIRECTLY */
/* eslint-disable */
import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export enum AboveOrBelow {
  above = 'above',
  below = 'below',
}

export type ActivateCustomerInput = {
  /** The id of the customer to activate */
  customerId: Scalars['String'];
};

export type ActivateCustomerResponse = {
  __typename: 'ActivateCustomerResponse';
  userId: Maybe<Scalars['String']>;
};

export type ActivationAddCardToUserInput = {
  last4: Scalars['String'];
  brand: Scalars['String'];
  /** Stripe card token */
  stripeToken: Scalars['String'];
  userId: Scalars['String'];
  /** The users activation token */
  token: Scalars['String'];
};

export type ActivationChangeDataAccessDataInput = {
  id: Scalars['String'];
  plan?: Maybe<SensorDataPlan>;
  gateway?: Maybe<Scalars['Boolean']>;
};

export type ActivationChangeDataAccessInput = {
  dataAccessData?: Maybe<Array<Maybe<ActivationChangeDataAccessDataInput>>>;
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type ActivationChangeMutationResponse = {
  __typename: 'ActivationChangeMutationResponse';
  /** The id of the user modified */
  userId: Maybe<Scalars['String']>;
};

export type ActivationChangeNameInput = {
  name: NameInput;
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type ActivationChangePasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type ActivationFinishInput = {
  token: Scalars['String'];
  userId: Scalars['String'];
  notifications?: Maybe<ActivationFinishNotificationsInput>;
  sharing?: Maybe<Array<Maybe<ActivationFinishSharingInput>>>;
};

export type ActivationFinishNotificationsInput = {
  triggerAlertThresholdHours: Scalars['Float'];
  textEnabled: Scalars['Boolean'];
  phone: Scalars['String'];
  emailEnabled: Scalars['Boolean'];
  email: Scalars['String'];
};

export type ActivationFinishPrepaidNotificationsInput = {
  triggerAlertThresholdHours: Scalars['Float'];
  textEnabled: Scalars['Boolean'];
  phone: Scalars['String'];
  emailEnabled: Scalars['Boolean'];
  email: Scalars['String'];
};

export type ActivationFinishSharingInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  andEdit: Scalars['Boolean'];
};

export type ActivationPrepaidFinishInput = {
  token: Scalars['String'];
  userId: Scalars['String'];
  notifications?: Maybe<ActivationFinishPrepaidNotificationsInput>;
  sharing?: Maybe<Array<Maybe<ActivationFinishSharingInput>>>;
};

export type ActivationProgress = {
  __typename: 'ActivationProgress';
  billing: Scalars['Boolean'];
  dataAccess: Scalars['Boolean'];
  name: Scalars['Boolean'];
  password: Scalars['Boolean'];
};

export type ActivationProgressInput = {
  userId: Scalars['String'];
  token: Scalars['String'];
};

export type ActivationProgressResponse = {
  __typename: 'ActivationProgressResponse';
  /** Activation URL for customer */
  activationUrl: Maybe<Scalars['String']>;
  /** The id of the user */
  id: Scalars['String'];
  /** The users name, if they have set it yet */
  name: Maybe<Name>;
  email: Maybe<Scalars['String']>;
  /** The users selected data plans */
  dataAccess: Maybe<Array<ActivationProgressResponseDataAccess>>;
  cardBrand: Maybe<Scalars['String']>;
  cardLast4: Maybe<Scalars['String']>;
  /** The email of the distributor who activated the customer */
  distributorEmail: Maybe<Scalars['String']>;
  /** The name of the distributor who activated the customer */
  distributorName: Maybe<Scalars['String']>;
  /** The email of the rep who activated the customer */
  repEmail: Maybe<Scalars['String']>;
  /** The name of the rep who activated the customer */
  repName: Maybe<Scalars['String']>;
  /** A list of the users gateways */
  gateways: Array<Gateway>;
  /** The type of activation.  Different questions will be asked based on how the user got an account. */
  type: Maybe<Scalars['String']>;
  /** How far the user has made it through the activation form */
  progress: ActivationProgress;
  /** A list of the users sensors */
  sensors: Array<Sensor>;
};

export type ActivationProgressResponseDataAccess = {
  __typename: 'ActivationProgressResponseDataAccess';
  id: Scalars['String'];
  plan: Maybe<SensorDataPlan>;
};

export type ActivationSharedFinishInput = {
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type AddCustomerAddressInput = {
  /** The id of the customer to add the address to */
  customerId: Scalars['String'];
  address: AddressInput;
};

export type AddCustomerAddressResponse = {
  __typename: 'AddCustomerAddressResponse';
  id: Maybe<Scalars['String']>;
};

export type AddCustomerInput = {
  /** Used when distributor is creating customer for rep */
  repId?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  email: Scalars['String'];
  name: NameInput;
  phone?: Maybe<Scalars['String']>;
};

export type AddCustomerResponse = {
  __typename: 'AddCustomerResponse';
  /** The id of the new customer */
  id: Maybe<Scalars['String']>;
};

export type AddDistributorInput = {
  email: Scalars['String'];
  name: NameInput;
  phone: Scalars['String'];
  /** ID of pricing sheet to use */
  pricing: Scalars['String'];
  salesRep: Scalars['String'];
};

export type AddDistributorInputBranding = {
  name?: Maybe<Scalars['String']>;
};

export type AddDistributorResponse = {
  __typename: 'AddDistributorResponse';
  /** The id of the new distributor */
  id: Maybe<Scalars['String']>;
};

export type AddFieldInput = {
  boundary?: Maybe<Array<NNLocationInput>>;
  boundarySoilStats?: Maybe<Array<FieldBoundaryInfoSoilStatInput>>;
  boundarySensorInfo?: Maybe<Array<FieldBoundaryInfoResultSensorInput>>;
  name: Scalars['String'];
  gateways?: Maybe<Array<Scalars['String']>>;
  /** Information about a fields irrigation */
  irrigationMetadata?: Maybe<FieldInputIrrigationMetadata>;
  sensors?: Maybe<Array<Scalars['String']>>;
  /** If a rep is adding a field to a customer, this must be supplied */
  customerId?: Maybe<Scalars['String']>;
  sensorWeights?: Maybe<Array<SensorWeightInput>>;
  estimatedAcreage?: Maybe<Scalars['Float']>;
  calculatedAcreage?: Maybe<Scalars['Float']>;
  /** Yield of this field */
  yield?: Maybe<YieldInput>;
};

export type AddOrderInput = {
  address: AddressInput;
  hardware: Array<AddOrderInputHardware>;
  repId: Scalars['String'];
  customerId?: Maybe<Scalars['String']>;
  prepaid?: Maybe<AddOrderInputPrepaid>;
  notes?: Maybe<Scalars['String']>;
  /**
   * If emails are provided, they will have the hardware shared with them upon
   * the order being bound
   * If set, sharingLevel and sharingName must also be set
   */
  sharingEmails?: Maybe<Array<Scalars['String']>>;
  sharingNames?: Maybe<Array<Scalars['String']>>;
  /** The level the sharingEmails should be shared at */
  sharingLevel?: Maybe<PermissionLevelInput>;
  /** If the order is managed service, this will be set */
  managedServiceOptions?: Maybe<AddOrderManagedServiceOptionsInput>;
};

export type AddOrderInputHardware = {
  model: ModelInput;
  quantity: Scalars['Int'];
};

export type AddOrderInputPrepaid = {
  plan: SensorDataPlan;
  months: Scalars['Int'];
  /**
   * Start date
   * If not set, will start when bound
   */
  start?: Maybe<Scalars['Float']>;
};

export type AddOrderManagedServiceOptionsInput = {
  /** The monthly cost of order */
  price: Scalars['Float'];
  /** Number of months it is for */
  months: Scalars['Int'];
  /** Whether or not it is prepaid managed service or monthly */
  prepaid: Scalars['Boolean'];
};

export type AddOrderResponse = {
  __typename: 'AddOrderResponse';
  /** The id of the new order */
  id: Maybe<Scalars['Int']>;
};

export type AddOrderResponseHardware = {
  __typename: 'AddOrderResponseHardware';
  model: Maybe<Model>;
  tempId: Maybe<Scalars['String']>;
};

export type AddPricingInput = {
  name: Scalars['String'];
  hardware: Array<AddPricingInputHardware>;
};

export type AddPricingInputHardware = {
  modelId: Scalars['String'];
  prices: Array<AddPricingInputHardwarePrice>;
};

export type AddPricingInputHardwarePrice = {
  quantity: Scalars['Int'];
  price: Scalars['Float'];
};

export type AddPricingResponse = {
  __typename: 'AddPricingResponse';
  /** The id of the new pricing object */
  id: Maybe<Scalars['String']>;
};

export type AddRepAddressInput = {
  repId: Scalars['String'];
  address: AddressInput;
};

export type AddRepAddressResponse = {
  __typename: 'AddRepAddressResponse';
  /** The id of the new address */
  id: Maybe<Scalars['String']>;
};

export type AddRepInput = {
  email: Scalars['String'];
  name: NameInput;
  phone: Scalars['String'];
  /** Used for creating rep as admin */
  distributorId?: Maybe<Scalars['String']>;
};

export type AddRepResponse = {
  __typename: 'AddRepResponse';
  /** The id of the new rep */
  id: Maybe<Scalars['String']>;
};

export type Address = {
  __typename: 'Address';
  city: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Maybe<Name>;
  state: Maybe<Scalars['String']>;
  street: Maybe<Scalars['String']>;
  zip: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city: Scalars['String'];
  name: NameInput;
  state: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type AddSoilInput = {
  name: Scalars['String'];
  t_wet?: Maybe<Scalars['Float']>;
  t_dry?: Maybe<Scalars['Float']>;
  c_wet?: Maybe<Scalars['Float']>;
  c_dry?: Maybe<Scalars['Float']>;
  clay_percent?: Maybe<Scalars['Float']>;
};

export type AddSoilResponse = {
  __typename: 'AddSoilResponse';
  success: Scalars['Boolean'];
  soil: Soil;
};

export type Alert = {
  __typename: 'Alert';
  last_monthly_report: Maybe<Scalars['Float']>;
  last_weekly_report: Maybe<Scalars['Float']>;
  last_login_nag: Maybe<Scalars['Float']>;
  last_soil_alert: Maybe<Scalars['Float']>;
  did_you_knows: Array<UsedDidYouKnows>;
  last_activation_reminder: Maybe<Scalars['Float']>;
  irrigationNeeded: Maybe<Scalars['Float']>;
};

export type ApplyPrepaidToUserInput = {
  email: Scalars['String'];
  /** Timestamp in ms */
  start: Scalars['Float'];
  end: Scalars['Float'];
  sensorIds: Array<Scalars['String']>;
  gatewayIds: Array<Scalars['String']>;
};

export type ApproveOrderInput = {
  /** The id of the order to approve */
  orderId: Scalars['Int'];
  /** A boolean designating if the order is to be approved or rejected */
  approved: Scalars['Boolean'];
};

export type ApproveOrderResponse = {
  __typename: 'ApproveOrderResponse';
  /** The id of the approved order */
  id: Maybe<Scalars['Int']>;
  /** Temporary ids given to hardware */
  hardware: Maybe<Array<Maybe<AddOrderResponseHardware>>>;
};

export type ArchivedField = {
  __typename: 'ArchivedField';
  /** YYYY-MM-DD */
  archiveDate: Scalars['String'];
  gateways: Array<ArchivedGateway>;
  id: Scalars['String'];
  irrigationMetadata: IrrigationMetadata;
  irrigationEvents: Array<IrrigationEvent>;
  moistureData: FieldMoistureDataResult;
  name: Scalars['String'];
  permissionLevel: Maybe<PermissionLevel>;
  /** If field contains hardware with rainfall data, this will be it */
  rainfallSource: Maybe<ArchivedHardware>;
  /** Season total rainfall entered by user during archive */
  seasonTotalRainfall: Maybe<Scalars['Float']>;
  /** Season total irrigation entered by user during archive, may not match cumulative total from Irrigation Events */
  seasonTotalIrrigation: Maybe<Scalars['Float']>;
  sensors: Array<ArchivedSensor>;
  sensorWeights: Array<SensorWeight>;
  owner: Maybe<HardwareUser>;
  users: Array<HardwareUser>;
  yield: YieldType;
  yieldData: YieldData;
};

export type ArchivedGateway = ArchivedHardware & {
  __typename: 'ArchivedGateway';
  /** YYYY-MM-DD */
  archiveDate: Scalars['String'];
  archiveId: Scalars['String'];
  dataView: Maybe<SensorDataView>;
  fields: Array<Maybe<Field>>;
  hasDataAccess: Scalars['Boolean'];
  userHasSensorAccess: Scalars['Boolean'];
  /** Returns true if this model of gateway has a rain gauge */
  hasRainGauge: Scalars['Boolean'];
  id: Scalars['String'];
  originalId: Scalars['String'];
  location: Maybe<Location>;
  model: Maybe<Model>;
  name: Scalars['String'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  /** Always null */
  plan: Maybe<SensorDataPlan>;
  sharingMaxUsers: Scalars['Int'];
  users: Array<HardwareUser>;
};

/** Archived Sensor or Gateway */
export type ArchivedHardware = {
  id: Scalars['String'];
  dataView: Maybe<SensorDataView>;
  hasDataAccess: Scalars['Boolean'];
  userHasSensorAccess: Scalars['Boolean'];
  hasRainGauge: Scalars['Boolean'];
  name: Scalars['String'];
  sharingMaxUsers: Scalars['Int'];
  owner: Maybe<HardwareUser>;
  plan: Maybe<SensorDataPlan>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type ArchivedIrrigationSystem = {
  __typename: 'ArchivedIrrigationSystem';
  /** YYYY-MM-DD */
  archiveDate: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  sensors: Array<ArchivedTrellisPressureSensor>;
  users: Array<HardwareUser>;
};

/** A sensor that has been archived */
export type ArchivedSensor = ArchivedHardware &
  SensorBase & {
    __typename: 'ArchivedSensor';
    /** YYYY-MM-DD */
    archiveDate: Scalars['String'];
    canDownloadData: Scalars['Boolean'];
    crop_type: Maybe<Crop>;
    crop_variety: Maybe<Scalars['String']>;
    dataView: Maybe<SensorDataView>;
    field: Maybe<Field>;
    /** YYYY-MM-DD */
    harvest_date: Maybe<Scalars['String']>;
    hasDataAccess: Scalars['Boolean'];
    hasRainfallData: Scalars['Boolean'];
    userHasSensorAccess: Scalars['Boolean'];
    id: Scalars['String'];
    originalId: Scalars['String'];
    location: Maybe<Location>;
    managementPlan: Maybe<SensorManagementPlan>;
    model: Maybe<Model>;
    moistureMethod: Maybe<MoistureMethod>;
    name: Scalars['String'];
    owner: Maybe<HardwareUser>;
    users: Array<HardwareUser>;
    isOwner: Scalars['Boolean'];
    permissionLevel: Maybe<PermissionLevel>;
    /** Always null */
    plan: Maybe<SensorDataPlan>;
    plant_date: Maybe<Scalars['String']>;
    sharingMaxUsers: Scalars['Int'];
    soil_type: Maybe<Soil>;
    /** Returns true if this model of sensor has a rain gauge */
    hasRainGauge: Scalars['Boolean'];
    weather: Weather;
  };

export type ArchivedTrellisPressureSensor = ArchivedTrellisSensor & {
  __typename: 'ArchivedTrellisPressureSensor';
  data: PressureSensorData;
  plan: Maybe<PressureSensorPlan>;
  irrigationSystem: Maybe<IrrigationSystem>;
  id: Scalars['String'];
  name: Scalars['String'];
  location: Maybe<Location>;
  isOwner: Scalars['Boolean'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type ArchivedTrellisSensor = {
  id: Scalars['String'];
  name: Scalars['String'];
  location: Maybe<Location>;
  isOwner: Scalars['Boolean'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type ArchiveFieldInput = {
  fieldId: Scalars['String'];
  seasonStartDate: Scalars['Float'];
  seasonEndDate: Scalars['Float'];
  yieldData?: Maybe<ArchiveFieldYieldDataInput>;
  /** Season total rainfall entered by user during archive */
  seasonTotalRainfall?: Maybe<Scalars['Float']>;
  /** Season total irrigation entered by user during archive, may not match cumulative total from Irrigation Events */
  seasonTotalIrrigation?: Maybe<Scalars['Float']>;
};

export type ArchiveFieldResponse = {
  __typename: 'ArchiveFieldResponse';
  success: Scalars['Boolean'];
};

export type ArchiveFieldYieldDataInput = {
  actualYield?: Maybe<YieldInput>;
  targetYield?: Maybe<YieldInput>;
  performance?: Maybe<YieldDataPerformance>;
};

export type ArchiveGatewayInput = {
  gatewayId: Scalars['String'];
  seasonStartDate: Scalars['Float'];
  seasonEndDate: Scalars['Float'];
};

export type ArchiveGatewayResponse = {
  __typename: 'ArchiveGatewayResponse';
  success: Scalars['Boolean'];
  archivedId: Scalars['String'];
};

export type ArchiveIrrigationSystemInput = {
  irrigationSystemId: Scalars['String'];
  seasonStartDate: Scalars['Float'];
  seasonEndDate: Scalars['Float'];
};

export type ArchiveIrrigationSystemResponse = {
  __typename: 'ArchiveIrrigationSystemResponse';
  success: Scalars['Boolean'];
};

export type ArchiveSensorInput = {
  sensorId: Scalars['String'];
  seasonStartDate: Scalars['Float'];
  seasonEndDate: Scalars['Float'];
};

export type ArchiveSensorResponse = {
  __typename: 'ArchiveSensorResponse';
  success: Scalars['Boolean'];
  archivedId: Scalars['String'];
};

export type BindOrderHardwareIds = {
  /** The temporary id given to the hardware */
  tempId: Scalars['String'];
  /** The id of the piece of hardware being bound */
  newId: Scalars['String'];
  model: ModelInput;
};

export type BindOrderInput = {
  /** The id of the order to update */
  orderId: Scalars['Int'];
  hardwareIds?: Maybe<Array<BindOrderHardwareIds>>;
};

export type BindOrderResponse = {
  __typename: 'BindOrderResponse';
  /** The id of the order that was bound */
  id: Maybe<Scalars['Int']>;
};

export enum CacheControlScope {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export type CancelPrepaidToUserInput = {
  email: Scalars['String'];
  sensorIds: Array<Scalars['String']>;
  gatewayIds: Array<Scalars['String']>;
};

export type ChangeCardInput = {
  stripeToken: Scalars['String'];
};

export type ChangeCardResponse = {
  __typename: 'ChangeCardResponse';
  /** The id of the user updated */
  id: Maybe<Scalars['String']>;
};

export enum ClaimHardwareIdType {
  SENSOR = 'SENSOR',
  GATEWAY = 'GATEWAY',
}

export type CommissionStructure = {
  __typename: 'CommissionStructure';
  value: Maybe<Scalars['Float']>;
  rate: Maybe<Scalars['Float']>;
};

export enum ConnectionStatus {
  online = 'online',
  warning = 'warning',
  offline = 'offline',
}

export type Crop = {
  __typename: 'Crop';
  id: Scalars['String'];
  name: Scalars['String'];
  didYouKnows: Array<CropDidYouKnow>;
  custom: Scalars['Boolean'];
};

export type CropAddInput = {
  name: Scalars['String'];
};

export type CropAddResponse = {
  __typename: 'CropAddResponse';
  crop: Crop;
};

export type CropDeleteInput = {
  id: Scalars['String'];
};

export type CropDeleteResponse = {
  __typename: 'CropDeleteResponse';
  success: Scalars['Boolean'];
};

export type CropDidYouKnow = {
  __typename: 'CropDidYouKnow';
  id: Scalars['String'];
  content: Scalars['String'];
};

export type CropUpdateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CropUpdateResponse = {
  __typename: 'CropUpdateResponse';
  crop: Crop;
};

export type CurrentPressure = {
  __typename: 'CurrentPressure';
  /** Latest data point */
  latest: SensorPressureData;
  /** Whether or not the pressure sensor is currently on */
  isOn: Scalars['Boolean'];
  scheduleResult: PressureScheduleResult;
};

export type CurrentPressureResult = CurrentPressure | SensorOffline;

export type CurrentWeatherResult = WeatherStationData | SensorOffline;

/** A static copy of a User that was saved when the distibutor added the customer. Can be edited independently. */
export type Customer = {
  __typename: 'Customer';
  addresses: Array<Address>;
  email: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Maybe<Name>;
  phone: Maybe<Scalars['String']>;
  repId: Maybe<Scalars['String']>;
  /** The user object of this customer.  Can be used to get sensors, data plans, etc */
  user: User;
};

export type DeleteDealerInput = {
  /** The id of the delear to be deleted */
  dealerId: Scalars['String'];
};

export type DeleteDealerResponse = {
  __typename: 'DeleteDealerResponse';
  /** The id of the deleted dealer */
  id: Scalars['String'];
};

export type DeleteFieldInput = {
  fieldId: Scalars['String'];
  /** If a rep is deleting a customers field, this must be supplied */
  customerId?: Maybe<Scalars['String']>;
};

export type DeleteSoilInput = {
  id: Scalars['String'];
};

export type DeleteSoilResponse = {
  __typename: 'DeleteSoilResponse';
  /** The id of the soil deleted */
  id: Scalars['String'];
};

export type Distributor = {
  __typename: 'Distributor';
  activationCompleted: Scalars['Boolean'];
  /** For a rep, this is all of their direct customers.  For a distributor, this is'direct customers + their rep's customers */
  customers: Array<Customer>;
  email: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Used on the distributor portal to determine if the admin stuff should show up */
  isAdmin: Maybe<Scalars['Boolean']>;
  isRep: Maybe<Scalars['Boolean']>;
  isDistributor: Maybe<Scalars['Boolean']>;
  name: Maybe<Name>;
  /** If this is a rep account, it will be the distributor that created the rep */
  parent: Maybe<Distributor>;
  phone: Maybe<Scalars['String']>;
  pricing: Pricing;
  reps: Array<DistributorRepCopy>;
  orders: Array<Order>;
  salesRep: Maybe<SalesRep>;
  stripeInfo: Maybe<DistributorStripeInfo>;
  /** Hardware that the distributor has purchased but not assigned to a customer */
  unassigned: DistributorUnassigned;
};

export type DistributorBranding = {
  __typename: 'DistributorBranding';
  name: Maybe<Scalars['String']>;
};

export type DistributorChangePasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type DistributorChangePasswordResponse = {
  __typename: 'DistributorChangePasswordResponse';
  /** The id of the new distributor changed */
  id: Maybe<Scalars['String']>;
};

/** A static copy of a Rep that was saved when the distributor added the rep. Can be edited independently. */
export type DistributorRepCopy = {
  __typename: 'DistributorRepCopy';
  addresses: Array<Address>;
  email: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Maybe<Name>;
  phone: Maybe<Scalars['String']>;
};

export type DistributorSignTC = {
  __typename: 'DistributorSignTC';
  id: Maybe<Scalars['String']>;
};

export type DistributorSignTCInput = {
  token: Scalars['String'];
  userId: Scalars['String'];
  tcText: Scalars['String'];
};

export type DistributorStripeInfo = {
  __typename: 'DistributorStripeInfo';
  /** Per Stripe: 'The URL expires a brief period after you retrieve it from the login_link API, so you should use it promptly.' */
  stripeURL: Scalars['String'];
};

export type DistributorStripeRegisterInput = {
  authCode: Scalars['String'];
};

export type DistributorStripeRegisterResponse = {
  __typename: 'DistributorStripeRegisterResponse';
  success: Scalars['Boolean'];
};

export type DistributorUnassigned = {
  __typename: 'DistributorUnassigned';
  sensors: Array<Sensor>;
  gateways: Array<Gateway>;
  /** Hardware which has not yet recieved a real id */
  unbound: Array<DistributorUnassignedUnbound>;
};

export type DistributorUnassignedUnbound = {
  __typename: 'DistributorUnassignedUnbound';
  id: Scalars['String'];
  model: Maybe<Model>;
  order: Maybe<Order>;
  orderId: Maybe<Scalars['Int']>;
};

export type DripStats = {
  /** Emitter flow rate (gallons/min) */
  emitterFlow?: Maybe<Scalars['Float']>;
  /** Emitter spacing along the line (cm) */
  emitterSpacing?: Maybe<Scalars['Float']>;
  /** Distance between drip lines (in) */
  dripDistance?: Maybe<Scalars['Float']>;
};

export type DripStatsType = {
  __typename: 'DripStatsType';
  emitterFlow: Maybe<Scalars['Float']>;
  emitterSpacing: Maybe<Scalars['Float']>;
  dripDistance: Maybe<Scalars['Float']>;
};

export type EditCustomerHardwareAddInputType = {
  sensorIds?: Maybe<Array<Scalars['String']>>;
  gatewayIds?: Maybe<Array<Scalars['String']>>;
};

export type EditCustomerHardwareInputType = {
  /** The id of the customer to edit */
  customerId: Scalars['String'];
  add?: Maybe<EditCustomerHardwareAddInputType>;
  remove?: Maybe<EditCustomerHardwareRemoveInputType>;
};

export type EditCustomerHardwareRemoveInputType = {
  sensorIds?: Maybe<Array<Scalars['String']>>;
  gatewayIds?: Maybe<Array<Scalars['String']>>;
};

export type EditCustomerHardwareResponse = {
  __typename: 'EditCustomerHardwareResponse';
  /** The id of the customer that was changed */
  id: Maybe<Scalars['String']>;
};

export type FakeHistoricalWeatherDataInput = {
  /** The id of the sensor to populate */
  sensorId: Scalars['String'];
  /** Timestamp ms to start */
  start: Scalars['Float'];
  /** Timestamp ms to end */
  end: Scalars['Float'];
};

export type FakeLocationDataInput = {
  /** The id of the sensor to populate */
  sensorId: Scalars['String'];
  /** The gw of the sensor to fake. Default is null */
  gatewayId?: Maybe<Scalars['String']>;
};

export type FakeLocationDataResponse = {
  __typename: 'FakeLocationDataResponse';
  loc: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type FakeSensorDataInput = {
  /** The id of the sensor to populate */
  sensorId: Scalars['String'];
  /** The gw of the sensor. Default is null */
  gatewayId?: Maybe<Scalars['String']>;
  /** Timestamp ms to start */
  start: Scalars['Float'];
  /** Timestamp ms to end */
  end: Scalars['Float'];
  /** The kind of data to populate it with */
  type: FakeSensorDataType;
};

export type FakeSensorDataResponse = {
  __typename: 'FakeSensorDataResponse';
  sensorId: Maybe<Scalars['String']>;
};

export enum FakeSensorDataType {
  /** No sensor data */
  none = 'none',
  /** 2 Moisture, 2 Temp */
  WWTT = 'WWTT',
  /** 3 Moisture, 1 Temp */
  WWWT = 'WWWT',
  /** Rain Gauge */
  RG = 'RG',
}

export type Field = {
  __typename: 'Field';
  boundary: Maybe<Array<Location>>;
  boundarySoilStats: Maybe<Array<FieldBoundaryInfoSoilStat>>;
  boundarySensorInfo: Maybe<Array<FieldBoundaryInfoResultSensor>>;
  current_state: Maybe<Scalars['Int']>;
  current_tension: Maybe<Scalars['Int']>;
  gateways: Array<Gateway>;
  hoursToDry: HoursToDry;
  id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
  irrigationMetadata: IrrigationMetadata;
  irrigationEvents: Array<IrrigationEvent>;
  moistureData: FieldMoistureDataResult;
  moistureProjection: FieldMoistureProjection;
  name: Scalars['String'];
  permissionLevel: Maybe<PermissionLevel>;
  /** If field contains hardware with rainfall data, this will be it */
  rainfallSource: Maybe<Hardware>;
  sensors: Array<Sensor>;
  sensorWeights: Array<SensorWeight>;
  owner: Maybe<HardwareUser>;
  users: Array<HardwareUser>;
  weatherStation: Maybe<TrellisWeatherSensor>;
  yield: YieldType;
  /** new acreage calculations */
  estimatedAcreage: Maybe<Scalars['Float']>;
  calculatedAcreage: Maybe<Scalars['Float']>;
};

export type FieldBoundaryInfoInput = {
  bounds: Array<NNLocationInput>;
  sensors: Array<FieldBoundaryInfoInputSensor>;
};

export type FieldBoundaryInfoInputSensor = {
  id: Scalars['String'];
  location: NNLocationInput;
};

export type FieldBoundaryInfoResult = {
  __typename: 'FieldBoundaryInfoResult';
  sensorsInBounds: Array<FieldBoundaryInfoResultSensor>;
  soilStats: Array<FieldBoundaryInfoSoilStat>;
  calculatedAcreage: Maybe<Scalars['Float']>;
};

export type FieldBoundaryInfoResultSensor = {
  __typename: 'FieldBoundaryInfoResultSensor';
  id: Scalars['String'];
  soil: Maybe<Soil>;
  /** % between 0 - 100 */
  soilPercent: Maybe<Scalars['Int']>;
};

export type FieldBoundaryInfoResultSensorInput = {
  id: Scalars['String'];
  soilId: Scalars['String'];
  soilPercent: Scalars['Int'];
};

export type FieldBoundaryInfoSoilStat = {
  __typename: 'FieldBoundaryInfoSoilStat';
  soil: Soil;
  soilPercent: Scalars['Int'];
};

export type FieldBoundaryInfoSoilStatInput = {
  soilId: Scalars['String'];
  soilPercent: Scalars['Int'];
};

export type FieldInputIrrigationMetadata = {
  irrigationType?: Maybe<Scalars['String']>;
  /** Source of irrigation water */
  waterSource?: Maybe<Scalars['String']>;
  /** Source of irrigation power */
  powerSource?: Maybe<Scalars['String']>;
  /** Vendor of the irrigation equipment */
  vendor?: Maybe<Scalars['String']>;
  /** The size of the field */
  acreage?: Maybe<Scalars['Float']>;
  /** Drip irrigation metadata */
  dripStats?: Maybe<DripStats>;
};

export type FieldMoistureData = {
  __typename: 'FieldMoistureData';
  moistureAvg: Array<FieldMoistureDataPoint>;
  /** Ordered list of soil threshold averages */
  thresholdsAvg: Array<MoistureThresholdPoint>;
  /** Moisture method of the data */
  moistureMethod: MoistureMethod;
  /** Errors if some sensors could not be used in the calculation */
  errors: Array<Scalars['String']>;
};

export type FieldMoistureDataError = {
  __typename: 'FieldMoistureDataError';
  messages: Array<Scalars['String']>;
};

export type FieldMoistureDataPoint = {
  __typename: 'FieldMoistureDataPoint';
  /** Time in unix ms */
  t: Scalars['Float'];
  /** Average soil moisture tension */
  sm: Scalars['Float'];
};

export type FieldMoistureDataResult =
  | FieldMoistureData
  | FieldMoistureDataError;

export type FieldMoistureProjection = {
  __typename: 'FieldMoistureProjection';
  data: Maybe<Array<FieldMoistureProjectionPoint>>;
  /** Any errors about projecting any of the sensors */
  errors: Array<Scalars['String']>;
};

export type FieldMoistureProjectionPoint = {
  __typename: 'FieldMoistureProjectionPoint';
  t: Scalars['Float'];
  sm: Scalars['Float'];
};

export type FieldMutationResponse = {
  __typename: 'FieldMutationResponse';
  success: Scalars['Boolean'];
  field: Maybe<Field>;
};

export type FieldSharingUpdateInput = {
  fieldId: Scalars['String'];
  /** The email of the user to share it with */
  email: Scalars['String'];
  /** The permission level to give the user, or null to remove the permission */
  level?: Maybe<PermissionLevelInput>;
};

export type FieldSharingUpdateResponse = {
  __typename: 'FieldSharingUpdateResponse';
  success: Scalars['Boolean'];
  sharedUid: Scalars['String'];
  field: Field;
};

export type FlashLibraryEntry = {
  __typename: 'FlashLibraryEntry';
  name: Scalars['String'];
  memoryLocation: Scalars['Float'];
  defaultValue: Scalars['String'];
  min: Maybe<Scalars['String']>;
  max: Maybe<Scalars['String']>;
};

export type Gateway = Hardware & {
  __typename: 'Gateway';
  /**
   * Whether or not the sensor is active.  Controls alerts, visibility on
   * dashboard, etc... Set true when the sensor's data is paid for, and false when
   * the sensor is archived.
   */
  active: Scalars['Boolean'];
  cell_signal: Maybe<Scalars['Float']>;
  /** The latest values reported by the gateway */
  configuration: Maybe<GatewayConfiguration>;
  configurationUpdated: Maybe<Scalars['Float']>;
  gpsUpdated: Maybe<Scalars['Float']>;
  gpsLocked: Maybe<Scalars['Boolean']>;
  /** Is the gateway online or offline? */
  connectionStatus: ConnectionStatus;
  currentPressure: Maybe<SensorPressureData>;
  currentWeather: Maybe<WeatherStationData>;
  data: Maybe<Array<GatewayData>>;
  dataView: Maybe<SensorDataView>;
  fields: Array<Maybe<Field>>;
  gateway_battery: Maybe<Scalars['Float']>;
  gateway_time: Maybe<Scalars['Float']>;
  hasDataAccess: Scalars['Boolean'];
  userHasSensorAccess: Scalars['Boolean'];
  /** Returns true if this model of gateway has a rain gauge */
  hasRainGauge: Scalars['Boolean'];
  id: Scalars['String'];
  /** Whether or not the sensor should appear on the distributor portal status page */
  inactive: Scalars['Boolean'];
  location: Maybe<Location>;
  mailbox: Array<MailboxSlot>;
  model: Maybe<Model>;
  name: Scalars['String'];
  network: Maybe<GatewayNetwork>;
  order: Maybe<Order>;
  owner: Maybe<HardwareUser>;
  isOwner: Scalars['Boolean'];
  permissionLevel: Maybe<PermissionLevel>;
  /**
   * If stripeSubActiveUntil is in the future, this will be it
   * This will be set regardless of if the sensor is cancelled or not
   */
  planActiveUntil: Maybe<Scalars['Float']>;
  /**
   * If stripeSubCancelledAt is in the future, this will be it
   * This should be set only if the sensor is scheduled to be cancelled at the end of the cycle
   */
  planCancelDate: Maybe<Scalars['Float']>;
  plan: Maybe<SensorDataPlan>;
  /** The currently selected plan for the gateway OR null if the gateway does not have an active subscription */
  activePlan: Maybe<SensorDataPlan>;
  /** Stripe plan ID. Use "plan" for frontend stuff. */
  planId: Maybe<StripeDataPlan>;
  /** Whether or not this piece of hardware needs a plan to access data */
  planRequired: Scalars['Boolean'];
  sensorData: Maybe<Array<SensorData>>;
  sensorIds: Array<Scalars['String']>;
  sensors: Array<Sensor>;
  serviceLogs: Array<ServiceLog>;
  sharingMaxUsers: Scalars['Int'];
  /** List of warnings for gateway */
  statusWarnings: Array<HardwareWarningEnum>;
  users: Array<HardwareUser>;
};

export type GatewaydataArgs = {
  limit: Maybe<Scalars['Int']>;
};

export type GatewayConfiguration = {
  __typename: 'GatewayConfiguration';
  boot: Maybe<Scalars['Boolean']>;
  crit_th: Maybe<Scalars['Float']>;
  freq: Maybe<Scalars['Float']>;
  hw_ver: Maybe<Scalars['String']>;
  led_th: Maybe<Scalars['Float']>;
  sw_ver: Maybe<Scalars['String']>;
  voltage_th: Maybe<Scalars['Float']>;
  ws_fw_ver: Maybe<Scalars['String']>;
};

export type GatewayData = {
  __typename: 'GatewayData';
  cell_signal: Maybe<Scalars['Float']>;
  netOpenRetry: Maybe<Scalars['Float']>;
  tcpOpenRetry: Maybe<Scalars['Float']>;
  tcpSendRetry: Maybe<Scalars['Float']>;
  failCounter: Maybe<Scalars['Float']>;
  resetCounter: Maybe<Scalars['Float']>;
  rebootCounter: Maybe<Scalars['Float']>;
  rxBadCounter: Maybe<Scalars['Float']>;
  localCounter: Maybe<Scalars['Float']>;
  rebootLimitCounter: Maybe<Scalars['Float']>;
  isCharging: Maybe<Scalars['Float']>;
  pwrOnRstCounter: Maybe<Scalars['Float']>;
  brnOutRstCounter: Maybe<Scalars['Float']>;
  extRstCounter: Maybe<Scalars['Float']>;
  wdtRstCounter: Maybe<Scalars['Float']>;
  tcpFailID: Maybe<Scalars['Float']>;
  msg: Maybe<Scalars['Float']>;
  time: Maybe<Scalars['Float']>;
  vbat: Maybe<Scalars['Float']>;
  vsol: Maybe<Scalars['Float']>;
  rbat: Maybe<Scalars['Float']>;
  lock: Maybe<Scalars['Boolean']>;
};

export type GatewayFlashInput = {
  gatewayId: Scalars['String'];
  location: Scalars['String'];
  value: Scalars['String'];
  name: Scalars['String'];
};

export type GatewayFlashResponse = {
  __typename: 'GatewayFlashResponse';
  success: Scalars['Boolean'];
};

export type GatewayNetwork = {
  __typename: 'GatewayNetwork';
  availableServices: Maybe<Array<Maybe<Scalars['String']>>>;
  ccid: Maybe<Scalars['String']>;
  currentService: Maybe<Scalars['String']>;
  firmwareVersion: Maybe<Scalars['String']>;
};

export type GatewayReprogramInput = {
  gatewayId: Scalars['String'];
  softwareVersion: Scalars['String'];
  hardwareVersion: Scalars['String'];
  variant: Scalars['String'];
  name: Scalars['String'];
};

export type GatewayReprogramResponse = {
  __typename: 'GatewayReprogramResponse';
  success: Scalars['Boolean'];
};

export type GatewayResetInput = {
  gatewayId: Scalars['String'];
  name: Scalars['String'];
};

export type GatewayResetResponse = {
  __typename: 'GatewayResetResponse';
  success: Scalars['Boolean'];
};

export type GatewaySimcomInput = {
  gatewayId: Scalars['String'];
  payload: Scalars['String'];
  name: Scalars['String'];
};

export type GatewaySimcomResponse = {
  __typename: 'GatewaySimcomResponse';
  success: Scalars['Boolean'];
};

export type GatewayStatusMutationResponse = {
  __typename: 'GatewayStatusMutationResponse';
  /** The id of the gateway modified */
  id: Maybe<Scalars['String']>;
};

/** Sensor or Gateway */
export type Hardware = {
  id: Scalars['String'];
  dataView: Maybe<SensorDataView>;
  hasDataAccess: Scalars['Boolean'];
  userHasSensorAccess: Scalars['Boolean'];
  hasRainGauge: Scalars['Boolean'];
  location: Maybe<Location>;
  name: Scalars['String'];
  planRequired: Scalars['Boolean'];
  sharingMaxUsers: Scalars['Int'];
  owner: Maybe<HardwareUser>;
  plan: Maybe<SensorDataPlan>;
  activePlan: Maybe<SensorDataPlan>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export enum HardwareTypeEnum {
  sensor = 'sensor',
  gateway = 'gateway',
  replacementSensorProbe = 'replacementSensorProbe',
  replacementEnclosure = 'replacementEnclosure',
  replacementAntenna = 'replacementAntenna',
  replacementGatewayEnclosure = 'replacementGatewayEnclosure',
  replacementSensorEnclosure = 'replacementSensorEnclosure',
  replacementRainGauge = 'replacementRainGauge',
  replacementWeatherStation = 'replacementWeatherStation',
  watermarkSensor = 'watermarkSensor',
  auger = 'auger',
  replacementCircuitBoard = 'replacementCircuitBoard',
  replacementSensorBoard = 'replacementSensorBoard',
  replacementGatewayBoard = 'replacementGatewayBoard',
  replacementMercuryModule = 'replacementMercuryModule',
}

/**
 * A limited version of a user, used for sharing
 * Admins can get more information via the user field
 */
export type HardwareUser = {
  __typename: 'HardwareUser';
  id: Scalars['String'];
  name: Maybe<Name>;
  accountEmail: Maybe<Scalars['String']>;
  permissionLevel: PermissionLevel;
  /** Admin only */
  user: User;
};

export type HardwareVersions = {
  __typename: 'HardwareVersions';
  name: Scalars['String'];
  variants: Array<Scalars['String']>;
};

export enum HardwareWarningEnum {
  BATTERY_LOW = 'BATTERY_LOW',
  SIGNAL_LOW = 'SIGNAL_LOW',
  NOT_REPORTED_RECENTLY = 'NOT_REPORTED_RECENTLY',
  ZERO_ADC = 'ZERO_ADC',
  INACTIVE_SUB = 'INACTIVE_SUB',
}

export type HoursToDry = {
  __typename: 'HoursToDry';
  prediction: Maybe<Scalars['Int']>;
  errors: Array<Scalars['String']>;
};

export type Invoice = {
  __typename: 'Invoice';
  date: Scalars['Float'];
  amount: Scalars['Float'];
};

export type Irrigation = {
  __typename: 'Irrigation';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type IrrigationEvent = {
  __typename: 'IrrigationEvent';
  id: Scalars['String'];
  /** Amount of irrigation in inches */
  amount: Scalars['Float'];
  /** Date of irrigation event (YYYY-MM-DD) */
  date: Scalars['String'];
  /** note about irrigation event */
  note: Maybe<Scalars['String']>;
  /** the source of the irrigation event (rainfall or irrigation) */
  source: IrrigationEventSource;
  /** If this event was automatically created, this is the origin */
  automatedOrigin: Maybe<IrrigationEventAutomatedOriginEnum>;
};

export type IrrigationEventAddInput = {
  /** id of field event took place in */
  fieldId: Scalars['String'];
  /** Amount of irrigation in inches */
  amount: Scalars['Float'];
  /** Date of irrigation event (YYYY-MM-DD) */
  date: Scalars['String'];
  /** note about irrigation event */
  note?: Maybe<Scalars['String']>;
  /**
   * the source of the irrigation event (rainfall or irrigation)
   * Default is irrigation
   */
  source?: Maybe<IrrigationEventSource>;
  /** If this is created automatically, include this */
  automatedOrigin?: Maybe<IrrigationEventAutomatedOriginEnum>;
};

export type IrrigationEventAddResponse = {
  __typename: 'IrrigationEventAddResponse';
  success: Scalars['Boolean'];
  /** The created irrigation event */
  irrigationEvent: IrrigationEvent;
};

export enum IrrigationEventAutomatedOriginEnum {
  DETECTED = 'DETECTED',
  WISECONN = 'WISECONN',
}

/** Deleted irrigation event */
export type IrrigationEventDeleteInput = {
  /** id of irrigation event to delete */
  id: Scalars['String'];
  /** id of field event took place in */
  fieldId: Scalars['String'];
};

export type IrrigationEventDeleteResponse = {
  __typename: 'IrrigationEventDeleteResponse';
  success: Scalars['Boolean'];
};

export enum IrrigationEventSource {
  IRRIGATION = 'IRRIGATION',
  RAINFALL = 'RAINFALL',
}

/** Updated irrigation event */
export type IrrigationEventUpdateInput = {
  /** id of irrigation event to update */
  id: Scalars['String'];
  /** id of field event took place in */
  fieldId: Scalars['String'];
  /** Updated amount of irrigation in inches */
  amount?: Maybe<Scalars['Float']>;
  /** Updated date of irrigation event (YYYY-MM-DD) */
  date?: Maybe<Scalars['String']>;
  /** Updates note */
  note?: Maybe<Scalars['String']>;
  /**
   * the source of the irrigation event (rainfall or irrigation)
   * Default is irrigation
   */
  source?: Maybe<IrrigationEventSource>;
};

export type IrrigationEventUpdateResponse = {
  __typename: 'IrrigationEventUpdateResponse';
  success: Scalars['Boolean'];
  /** The created irrigation event */
  irrigationEvent: IrrigationEvent;
};

export type IrrigationMetadata = {
  __typename: 'IrrigationMetadata';
  irrigationType: Maybe<Scalars['String']>;
  /** Source of irrigation water */
  waterSource: Maybe<Scalars['String']>;
  /** Source of irrigation power */
  powerSource: Maybe<Scalars['String']>;
  /** Vendor of the irrigation equipment */
  vendor: Maybe<Scalars['String']>;
  /** The size of the field */
  acreage: Maybe<Scalars['Float']>;
  /** Drip irrigation metadata */
  dripStats: DripStatsType;
};

export type IrrigationSystem = {
  __typename: 'IrrigationSystem';
  id: Scalars['String'];
  name: Scalars['String'];
  sensors: Array<TrellisPressureSensor>;
  users: Array<HardwareUser>;
};

export type IrrigationSystemAddInput = {
  name: Scalars['String'];
  sensorIds: Array<Scalars['String']>;
  sensorPlans: Array<PressureSensorPlanInput>;
};

export type IrrigationSystemDeleteResponse = {
  __typename: 'IrrigationSystemDeleteResponse';
  success: Scalars['Boolean'];
};

export type IrrigationSystemUpdateInput = {
  name: Scalars['String'];
  sensorIds: Array<Scalars['String']>;
  sensorPlans: Array<PressureSensorPlanInput>;
};

export type Location = {
  __typename: 'Location';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type LocationInput = {
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type MailboxSlot = {
  __typename: 'MailboxSlot';
  toDevice: Maybe<Scalars['String']>;
  toServer: Maybe<Scalars['String']>;
};

export type ManagedServiceOptions = {
  __typename: 'ManagedServiceOptions';
  /** The monthly cost of order */
  price: Scalars['Float'];
  /** Number of months it is for */
  months: Scalars['Int'];
  /** Whether or not it is prepaid managed service or monthly */
  prepaid: Scalars['Boolean'];
  /** When the service started */
  startTime: Maybe<Scalars['Float']>;
};

export type ManagedServiceStatus = {
  __typename: 'ManagedServiceStatus';
  orderId: Scalars['Int'];
  invoiceUrl: Maybe<Scalars['String']>;
  /** If the first payment for the order has been made */
  initialPaymentMade: Scalars['Boolean'];
  prepaid: Scalars['Boolean'];
  /** Whether or not the subscription has been started */
  subscriptionStarted: Scalars['Boolean'];
  /** Unix ms start timestamp */
  subscriptionStartTime: Maybe<Scalars['Float']>;
  /** The last month that has been paid (1 is first) */
  lastPaidMonth: Maybe<Scalars['Int']>;
  /** True if all invoices for order have been paid */
  paidInFull: Scalars['Boolean'];
};

export type Model = {
  __typename: 'Model';
  antennaLength: Maybe<Scalars['Float']>;
  moistureDepths: Maybe<Array<Scalars['Float']>>;
  temperatureDepths: Maybe<Array<Scalars['Float']>>;
  ecDepths: Maybe<Array<Scalars['Float']>>;
  hasWeatherStation: Scalars['Boolean'];
  hasRainGauge: Scalars['Boolean'];
  hasPressureSensor: Scalars['Boolean'];
  moistureMethod: Maybe<MoistureMethod>;
  probeless: Scalars['Boolean'];
  custom: Scalars['Boolean'];
  type: HardwareTypeEnum;
  /**
   * For a standalone watermark senosr, the lead length in feet
   * For a watermark auger, the length of the auger in feet
   * For a sentek auger, the length of the auger in cm
   */
  length: Maybe<Scalars['Float']>;
  hasSpigot: Scalars['Boolean'];
  softwareVersion: Maybe<Scalars['String']>;
};

export type ModelInput = {
  antennaLength?: Maybe<Scalars['Float']>;
  moistureDepths?: Maybe<Array<Scalars['Float']>>;
  temperatureDepths?: Maybe<Array<Scalars['Float']>>;
  ecDepths?: Maybe<Array<Scalars['Float']>>;
  hasWeatherStation?: Maybe<Scalars['Boolean']>;
  hasRainGauge?: Maybe<Scalars['Boolean']>;
  hasPressureSensor?: Maybe<Scalars['Boolean']>;
  moistureMethod?: Maybe<MoistureMethod>;
  type: HardwareTypeEnum;
  probeless?: Maybe<Scalars['Boolean']>;
  custom?: Maybe<Scalars['Boolean']>;
  /**
   * For a standalone watermark senosr, the lead length in feet
   * For a watermark auger, the length of the auger in feet
   * For a sentek auger, the length of the auger in cm
   */
  length?: Maybe<Scalars['Float']>;
  hasSpigot?: Maybe<Scalars['Boolean']>;
  softwareVersion?: Maybe<Scalars['String']>;
};

export enum MoistureMethod {
  capacitance = 'capacitance',
  tension = 'tension',
}

export type MoistureThresholdPoint = {
  __typename: 'MoistureThresholdPoint';
  wet: Scalars['Float'];
  dry: Scalars['Float'];
  time: Scalars['Float'];
};

export type MonthlyInvoiceManagedServiceInput = {
  orderId: Scalars['Int'];
};

export type MonthlyInvoiceManagedServiceResponse = {
  __typename: 'MonthlyInvoiceManagedServiceResponse';
  success: Scalars['Boolean'];
};

/** Trellis mutations */
export type Mutation = {
  __typename: 'Mutation';
  /** Register a new user */
  registerUser: Maybe<RegistrationMutationResponse>;
  /** Send user activationtyp email */
  sendVerificationEmail: Maybe<RegistrationMutationResponse>;
  /** Add a field */
  addField: Maybe<FieldMutationResponse>;
  /** Delete a field */
  deleteField: Maybe<FieldMutationResponse>;
  /** Update a field */
  updateField: Maybe<UpdateFieldMutationResponse>;
  /** Update sensor status */
  updateSensorStatus: Maybe<SensorMutationResponse>;
  /** Update a sensor */
  updateSensor: Maybe<SensorMutationResponse>;
  /** Update sensor alert */
  updateSensorAlert: Maybe<SensorMutationResponse>;
  /** Update sensor state */
  updateSensorState: Maybe<SensorMutationResponse>;
  /** Add a card to a user */
  activationAddCardToUser: Maybe<ActivationChangeMutationResponse>;
  /** Change a users name */
  activationChangeName: Maybe<ActivationChangeMutationResponse>;
  /** Change a users data access */
  activationChangeDataAccess: Maybe<ActivationChangeMutationResponse>;
  /** Change a users password */
  activationChangePassword: Maybe<ActivationChangeMutationResponse>;
  /** Finish user activation process */
  activationFinish: Maybe<ActivationChangeMutationResponse>;
  /** Finish shared user activation process */
  activationSharedFinish: Maybe<ActivationChangeMutationResponse>;
  /** Finish prepaid user activation process */
  activationPrepaidFinish: Maybe<ActivationChangeMutationResponse>;
  /** Resend activation email */
  activationResendEmail: Scalars['Boolean'];
  /** Update a user's data plan */
  updateDataPlan: Maybe<UpdateDataPlanResponse>;
  /** Change a user's card */
  changeCard: Maybe<ChangeCardResponse>;
  /** Change a user's preferences */
  updateUserPreferences: Maybe<UpdateUserPreferencesResponse>;
  /** Apply prepaid to a user */
  applyPrepaidToUser: Maybe<Scalars['Boolean']>;
  /** Cancel prepaid to a user */
  cancelPrepaidToUser: Maybe<Scalars['Boolean']>;
  /** Change a user's profile */
  updateUserProfile: Maybe<UpdateUserProfileResponse>;
  /** Add a login for a user */
  updateLoginTracking: Maybe<UpdateLoginResponse>;
  /** Change a user's alert history */
  updateAlertHistory: Maybe<UpdateAlertHistoryResponse>;
  /** Set a user's push token */
  userSetPushToken: Maybe<UserSetPushTokenResponse>;
  /** Update gateway hardware_status */
  updateGatewayStatus: Maybe<GatewayStatusMutationResponse>;
  /** Update gateway */
  updateGateway: Maybe<UpdateGatewayMutationResponse>;
  /** Add a pricing object */
  addPricing: Maybe<AddPricingResponse>;
  /** Add a distributor */
  addDistributor: Maybe<AddDistributorResponse>;
  /** Add a Rep */
  addRep: Maybe<AddRepResponse>;
  /** Delete a dealer */
  deleteDealer: DeleteDealerResponse;
  /** Add a shipping address for a Rep */
  addRepAddress: Maybe<AddRepAddressResponse>;
  /** Change a distributors password */
  changeDistributorPassword: Maybe<DistributorChangePasswordResponse>;
  /** Activate a customer's account */
  activateCustomer: Maybe<ActivateCustomerResponse>;
  /** Add a customer for the logged in user */
  addCustomer: Maybe<AddCustomerResponse>;
  /** Add an address to a customer for the logged in user */
  addCustomerAddress: Maybe<AddCustomerAddressResponse>;
  /** Add/remove from customers hardware */
  editCustomerHardware: Maybe<EditCustomerHardwareResponse>;
  /** Remove a customer */
  removeCustomer: Maybe<RemoveCustomerResponse>;
  /** Add an order */
  addOrder: Maybe<AddOrderResponse>;
  /** Approve an order */
  approveOrder: Maybe<ApproveOrderResponse>;
  /** Bind an order (Set the ids) */
  bindOrder: Maybe<BindOrderResponse>;
  /** Mark an order as shipped */
  shipOrder: Maybe<ShipOrderResponse>;
  /** Populate a sensor with fake data */
  fakeSensorData: Maybe<FakeSensorDataResponse>;
  /** Populate a sensor with fake location data */
  fakeLocationData: Maybe<FakeLocationDataResponse>;
  /** Populate a sensor with fake historical weather data */
  fakeHistoricalWeatherData: Maybe<FakeSensorDataResponse>;
  /** Add a soil for current user */
  addSoil: AddSoilResponse;
  /** Delete a soil for current user */
  deleteSoil: DeleteSoilResponse;
  /** Update a soil for current user */
  updateSoil: UpdateSoilResponse;
  /** Add a crop type for current user */
  cropAdd: CropAddResponse;
  /** Delete a crop type for current user */
  cropDelete: CropDeleteResponse;
  /** Update a crop type for current user */
  cropUpdate: CropUpdateResponse;
  /** Share or unshare a sensor */
  updateSensorSharing: Maybe<UpdateSensorSharingResponse>;
  /** Share or unshare a gateway */
  updateGatewaySharing: Maybe<UpdateGatewaySharingResponse>;
  /** Share a field and the hardware in it */
  fieldSharingUpdate: Maybe<FieldSharingUpdateResponse>;
  /** Add an irrigation event */
  irrigationEventAdd: Maybe<IrrigationEventAddResponse>;
  /** Update an irrigation event */
  irrigationEventUpdate: Maybe<IrrigationEventUpdateResponse>;
  /** Delete an irrigation event */
  irrigationEventDelete: Maybe<IrrigationEventDeleteResponse>;
  /** Create a service log */
  serviceLogCreate: Maybe<ServiceLogCreateReponse>;
  /** Update a service log */
  serviceLogUpdate: Maybe<ServiceLogUpdateReponse>;
  /** Delete a service log */
  serviceLogDelete: Maybe<ServiceLogDeleteReponse>;
  /** Add announcement about something new! */
  notificationAnnouncementAdd: Maybe<NotificationAnnouncementAdd>;
  /** Add announcement about new release! */
  notificationReleaseAdd: Maybe<NotificationReleaseAdd>;
  /** Add notification about a dry sensor */
  notificationDrySensorAdd: Maybe<NotificationDrySensorAdd>;
  /** Add notification about a sensor soil type being populated automatically */
  notificationSoilTypePopulateAdd: Maybe<NotificationSoilTypePopulateAdd>;
  /** Mark notifications as read */
  notificationMarkAsRead: Maybe<NotificationMarkAsRead>;
  /** Archive a sensor */
  archiveSensor: Maybe<ArchiveSensorResponse>;
  /** Archive a gateway */
  archiveGateway: Maybe<ArchiveGatewayResponse>;
  /** Archive a field */
  archiveField: Maybe<ArchiveFieldResponse>;
  /** Archive an irrigation system */
  archiveIrrigationSystem: ArchiveIrrigationSystemResponse;
  /** Sign distributor terms and conditions */
  distributorSignTC: Maybe<DistributorSignTC>;
  /** Claim hardware id (returns the id) */
  claimHardwareId: Scalars['String'];
  sensorManagementPlanAdd: SensorManagementPlanAddResponse;
  sensorManagementPlanUpdate: SensorManagementPlanResponse;
  sensorManagementPlanDelete: SensorManagementPlanResponse;
  weatherStationManagementPlanAdd: WeatherStationManagementPlanResponse;
  weatherStationManagementPlanUpdate: WeatherStationManagementPlanResponse;
  weatherStationManagementPlanDelete: WeatherStationManagementPlanResponse;
  postPerformance: PostPerformanceResponse;
  commandGatewayResetSend: GatewayResetResponse;
  commandGatewayFlashSend: GatewayFlashResponse;
  commandGatewaySimcomSend: GatewaySimcomResponse;
  commandGatewayReprogramSend: GatewayReprogramResponse;
  commandSensorResetSend: SensorResetResponse;
  commandSensorRetrySend: SensorRetryResponse;
  commandSensorEditSend: SensorEditResponse;
  commandSensorReprogramSend: SensorReprogramResponse;
  desktopDebuggerSendLogs: Scalars['Boolean'];
  weatherStationUpdate: WeatherStationUpdateResponse;
  pressureSensorUpdate: TrellisPressureSensor;
  irrigationSystemAdd: IrrigationSystem;
  irrigationSystemUpdate: IrrigationSystem;
  irrigationSystemDelete: IrrigationSystemDeleteResponse;
  distributorStripeRegister: DistributorStripeRegisterResponse;
  /**
   * Managed service orders do not begin when they are paid
   * Instead, users or dealers trigger the start
   */
  startManagedService: StartManagedServiceResponse;
  /**
   * Renew managed service.  Should be called by backend a week before the next
   * year starts
   */
  renewManagedService: RenewManagedServiceResponse;
  transferFundsManagedService: TransferFundsManagedServiceReponse;
  monthlyInvoiceManagedService: MonthlyInvoiceManagedServiceResponse;
  sendSupportMessage: SendSupportMessageResponse;
};

/** Trellis mutations */
export type MutationregisterUserArgs = {
  input: RegisterUserInput;
};

/** Trellis mutations */
export type MutationsendVerificationEmailArgs = {
  input: SendVerificationEmailInput;
};

/** Trellis mutations */
export type MutationaddFieldArgs = {
  input: AddFieldInput;
};

/** Trellis mutations */
export type MutationdeleteFieldArgs = {
  input: Maybe<DeleteFieldInput>;
};

/** Trellis mutations */
export type MutationupdateFieldArgs = {
  fieldId: Scalars['String'];
  input: UpdateFieldInput;
};

/** Trellis mutations */
export type MutationupdateSensorStatusArgs = {
  sensorId: Scalars['String'];
  input: UpdateSensorStatusInput;
};

/** Trellis mutations */
export type MutationupdateSensorArgs = {
  sensorId: Scalars['String'];
  input: UpdateSensorInput;
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis mutations */
export type MutationupdateSensorAlertArgs = {
  id: Scalars['String'];
  type: Scalars['String'];
};

/** Trellis mutations */
export type MutationupdateSensorStateArgs = {
  input: UpdateSensorStateInput;
};

/** Trellis mutations */
export type MutationactivationAddCardToUserArgs = {
  input: ActivationAddCardToUserInput;
};

/** Trellis mutations */
export type MutationactivationChangeNameArgs = {
  input: ActivationChangeNameInput;
};

/** Trellis mutations */
export type MutationactivationChangeDataAccessArgs = {
  input: ActivationChangeDataAccessInput;
};

/** Trellis mutations */
export type MutationactivationChangePasswordArgs = {
  input: ActivationChangePasswordInput;
};

/** Trellis mutations */
export type MutationactivationFinishArgs = {
  input: ActivationFinishInput;
};

/** Trellis mutations */
export type MutationactivationSharedFinishArgs = {
  input: ActivationSharedFinishInput;
};

/** Trellis mutations */
export type MutationactivationPrepaidFinishArgs = {
  input: ActivationPrepaidFinishInput;
};

/** Trellis mutations */
export type MutationactivationResendEmailArgs = {
  customerId: Scalars['String'];
};

/** Trellis mutations */
export type MutationupdateDataPlanArgs = {
  input: UpdateDataPlanInput;
};

/** Trellis mutations */
export type MutationchangeCardArgs = {
  input: ChangeCardInput;
};

/** Trellis mutations */
export type MutationupdateUserPreferencesArgs = {
  input: UpdateUserPreferencesInput;
};

/** Trellis mutations */
export type MutationapplyPrepaidToUserArgs = {
  input: ApplyPrepaidToUserInput;
};

/** Trellis mutations */
export type MutationcancelPrepaidToUserArgs = {
  input: CancelPrepaidToUserInput;
};

/** Trellis mutations */
export type MutationupdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};

/** Trellis mutations */
export type MutationupdateLoginTrackingArgs = {
  input: UpdateLoginInput;
};

/** Trellis mutations */
export type MutationupdateAlertHistoryArgs = {
  input: UpdateAlertHistoryInput;
};

/** Trellis mutations */
export type MutationuserSetPushTokenArgs = {
  input: UserSetPushTokenInput;
};

/** Trellis mutations */
export type MutationupdateGatewayStatusArgs = {
  input: UpdateGatewayStatusInput;
};

/** Trellis mutations */
export type MutationupdateGatewayArgs = {
  input: UpdateGatewayInput;
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis mutations */
export type MutationaddPricingArgs = {
  input: AddPricingInput;
};

/** Trellis mutations */
export type MutationaddDistributorArgs = {
  input: AddDistributorInput;
};

/** Trellis mutations */
export type MutationaddRepArgs = {
  input: AddRepInput;
};

/** Trellis mutations */
export type MutationdeleteDealerArgs = {
  input: DeleteDealerInput;
};

/** Trellis mutations */
export type MutationaddRepAddressArgs = {
  input: AddRepAddressInput;
};

/** Trellis mutations */
export type MutationchangeDistributorPasswordArgs = {
  input: DistributorChangePasswordInput;
};

/** Trellis mutations */
export type MutationactivateCustomerArgs = {
  input: ActivateCustomerInput;
};

/** Trellis mutations */
export type MutationaddCustomerArgs = {
  input: AddCustomerInput;
};

/** Trellis mutations */
export type MutationaddCustomerAddressArgs = {
  input: AddCustomerAddressInput;
};

/** Trellis mutations */
export type MutationeditCustomerHardwareArgs = {
  input: EditCustomerHardwareInputType;
};

/** Trellis mutations */
export type MutationremoveCustomerArgs = {
  input: RemoveCustomerInput;
};

/** Trellis mutations */
export type MutationaddOrderArgs = {
  input: AddOrderInput;
};

/** Trellis mutations */
export type MutationapproveOrderArgs = {
  input: ApproveOrderInput;
};

/** Trellis mutations */
export type MutationbindOrderArgs = {
  input: BindOrderInput;
};

/** Trellis mutations */
export type MutationshipOrderArgs = {
  input: ShipOrderInput;
};

/** Trellis mutations */
export type MutationfakeSensorDataArgs = {
  input: FakeSensorDataInput;
};

/** Trellis mutations */
export type MutationfakeLocationDataArgs = {
  input: FakeLocationDataInput;
};

/** Trellis mutations */
export type MutationfakeHistoricalWeatherDataArgs = {
  input: FakeHistoricalWeatherDataInput;
};

/** Trellis mutations */
export type MutationaddSoilArgs = {
  input: AddSoilInput;
};

/** Trellis mutations */
export type MutationdeleteSoilArgs = {
  input: DeleteSoilInput;
};

/** Trellis mutations */
export type MutationupdateSoilArgs = {
  input: UpdateSoilInput;
};

/** Trellis mutations */
export type MutationcropAddArgs = {
  input: CropAddInput;
};

/** Trellis mutations */
export type MutationcropDeleteArgs = {
  input: CropDeleteInput;
};

/** Trellis mutations */
export type MutationcropUpdateArgs = {
  input: CropUpdateInput;
};

/** Trellis mutations */
export type MutationupdateSensorSharingArgs = {
  input: UpdateSensorSharingInput;
};

/** Trellis mutations */
export type MutationupdateGatewaySharingArgs = {
  input: UpdateGatewaySharingInput;
};

/** Trellis mutations */
export type MutationfieldSharingUpdateArgs = {
  input: FieldSharingUpdateInput;
};

/** Trellis mutations */
export type MutationirrigationEventAddArgs = {
  input: IrrigationEventAddInput;
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis mutations */
export type MutationirrigationEventUpdateArgs = {
  input: IrrigationEventUpdateInput;
};

/** Trellis mutations */
export type MutationirrigationEventDeleteArgs = {
  input: IrrigationEventDeleteInput;
};

/** Trellis mutations */
export type MutationserviceLogCreateArgs = {
  input: ServiceLogCreateInput;
};

/** Trellis mutations */
export type MutationserviceLogUpdateArgs = {
  input: ServiceLogUpdateInput;
};

/** Trellis mutations */
export type MutationserviceLogDeleteArgs = {
  input: ServiceLogDeleteInput;
};

/** Trellis mutations */
export type MutationnotificationAnnouncementAddArgs = {
  input: Maybe<NotificationAnnouncementInput>;
};

/** Trellis mutations */
export type MutationnotificationReleaseAddArgs = {
  input: Maybe<NotificationReleaseInput>;
};

/** Trellis mutations */
export type MutationnotificationDrySensorAddArgs = {
  input: Maybe<NotificationDrySensorInput>;
};

/** Trellis mutations */
export type MutationnotificationSoilTypePopulateAddArgs = {
  input: Maybe<NotificationSoilTypePopulateInput>;
};

/** Trellis mutations */
export type MutationnotificationMarkAsReadArgs = {
  input: Maybe<NotificationMarkAsReadInput>;
};

/** Trellis mutations */
export type MutationarchiveSensorArgs = {
  input: ArchiveSensorInput;
};

/** Trellis mutations */
export type MutationarchiveGatewayArgs = {
  input: ArchiveGatewayInput;
};

/** Trellis mutations */
export type MutationarchiveFieldArgs = {
  input: ArchiveFieldInput;
};

/** Trellis mutations */
export type MutationarchiveIrrigationSystemArgs = {
  input: ArchiveIrrigationSystemInput;
};

/** Trellis mutations */
export type MutationdistributorSignTCArgs = {
  input: DistributorSignTCInput;
};

/** Trellis mutations */
export type MutationclaimHardwareIdArgs = {
  type: ClaimHardwareIdType;
};

/** Trellis mutations */
export type MutationsensorManagementPlanAddArgs = {
  sensorId: Scalars['String'];
  input: SensorManagementPlanInput;
};

/** Trellis mutations */
export type MutationsensorManagementPlanUpdateArgs = {
  planId: Scalars['String'];
  input: SensorManagementPlanInput;
};

/** Trellis mutations */
export type MutationsensorManagementPlanDeleteArgs = {
  planId: Scalars['String'];
};

/** Trellis mutations */
export type MutationweatherStationManagementPlanAddArgs = {
  input: WeatherStationManagementPlanInput;
};

/** Trellis mutations */
export type MutationweatherStationManagementPlanUpdateArgs = {
  planId: Scalars['String'];
  input: WeatherStationManagementPlanInput;
};

/** Trellis mutations */
export type MutationweatherStationManagementPlanDeleteArgs = {
  planId: Scalars['String'];
};

/** Trellis mutations */
export type MutationpostPerformanceArgs = {
  input: PerformanceStatisticsInput;
};

/** Trellis mutations */
export type MutationcommandGatewayResetSendArgs = {
  input: GatewayResetInput;
};

/** Trellis mutations */
export type MutationcommandGatewayFlashSendArgs = {
  input: GatewayFlashInput;
};

/** Trellis mutations */
export type MutationcommandGatewaySimcomSendArgs = {
  input: GatewaySimcomInput;
};

/** Trellis mutations */
export type MutationcommandGatewayReprogramSendArgs = {
  input: GatewayReprogramInput;
};

/** Trellis mutations */
export type MutationcommandSensorResetSendArgs = {
  input: SensorResetInput;
};

/** Trellis mutations */
export type MutationcommandSensorRetrySendArgs = {
  input: SensorRetryInput;
};

/** Trellis mutations */
export type MutationcommandSensorEditSendArgs = {
  input: SensorEditInput;
};

/** Trellis mutations */
export type MutationcommandSensorReprogramSendArgs = {
  input: SensorReprogramInput;
};

/** Trellis mutations */
export type MutationdesktopDebuggerSendLogsArgs = {
  data: Scalars['String'];
};

/** Trellis mutations */
export type MutationweatherStationUpdateArgs = {
  id: Scalars['String'];
  input: WeatherStationUpdateInput;
};

/** Trellis mutations */
export type MutationpressureSensorUpdateArgs = {
  id: Scalars['String'];
  input: PressureSensorUpdateInput;
};

/** Trellis mutations */
export type MutationirrigationSystemAddArgs = {
  input: IrrigationSystemAddInput;
};

/** Trellis mutations */
export type MutationirrigationSystemUpdateArgs = {
  id: Scalars['String'];
  input: IrrigationSystemUpdateInput;
};

/** Trellis mutations */
export type MutationirrigationSystemDeleteArgs = {
  id: Scalars['String'];
};

/** Trellis mutations */
export type MutationdistributorStripeRegisterArgs = {
  input: DistributorStripeRegisterInput;
};

/** Trellis mutations */
export type MutationstartManagedServiceArgs = {
  input: StartManagedServiceInput;
};

/** Trellis mutations */
export type MutationrenewManagedServiceArgs = {
  input: RenewManagedServiceInput;
};

/** Trellis mutations */
export type MutationtransferFundsManagedServiceArgs = {
  input: TransferFundsManagedServiceInput;
};

/** Trellis mutations */
export type MutationmonthlyInvoiceManagedServiceArgs = {
  input: MonthlyInvoiceManagedServiceInput;
};

/** Trellis mutations */
export type MutationsendSupportMessageArgs = {
  body: Scalars['String'];
};

export type Name = {
  __typename: 'Name';
  first: Maybe<Scalars['String']>;
  last: Maybe<Scalars['String']>;
  /** Combined first and last name */
  full: Maybe<Scalars['String']>;
};

export type NameInput = {
  first: Scalars['String'];
  last: Scalars['String'];
};

export type NNLocationInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

/** Notifications that show up on dashboard */
export type Notification = {
  id: Scalars['String'];
  read: Scalars['Boolean'];
  timestamp: Maybe<Scalars['Float']>;
};

/** Dashboard Announcement */
export type NotificationAnnouncement = Notification & {
  __typename: 'NotificationAnnouncement';
  id: Scalars['String'];
  read: Scalars['Boolean'];
  timestamp: Maybe<Scalars['Float']>;
  primary: Scalars['String'];
  secondary: Scalars['String'];
  href: Maybe<Scalars['String']>;
  /** 7/9 graphqlgen crashes if it's named popup or popupId :) */
  popupToShow: Maybe<PopupId>;
};

export type NotificationAnnouncementAdd = {
  __typename: 'NotificationAnnouncementAdd';
  success: Scalars['Boolean'];
};

export type NotificationAnnouncementInput = {
  userId: Scalars['String'];
  primary: Scalars['String'];
  secondary: Scalars['String'];
  href?: Maybe<Scalars['String']>;
  /** 7/9 graphqlgen crashes if it's named popup or popupId :) */
  popupToShow?: Maybe<PopupId>;
};

/** Dry sensor notification */
export type NotificationDrySensor = Notification & {
  __typename: 'NotificationDrySensor';
  id: Scalars['String'];
  read: Scalars['Boolean'];
  timestamp: Maybe<Scalars['Float']>;
  sensorId: Scalars['String'];
};

export type NotificationDrySensorAdd = {
  __typename: 'NotificationDrySensorAdd';
  success: Scalars['Boolean'];
};

export type NotificationDrySensorInput = {
  userId: Scalars['String'];
  timestamp: Scalars['Float'];
  sensorId: Scalars['String'];
};

export type NotificationMarkAsRead = {
  __typename: 'NotificationMarkAsRead';
  success: Scalars['Boolean'];
  notifications: Array<Notification>;
};

export type NotificationMarkAsReadInput = {
  notificationIds: Array<Scalars['String']>;
};

/** Dashboard Release */
export type NotificationRelease = Notification & {
  __typename: 'NotificationRelease';
  id: Scalars['String'];
  read: Scalars['Boolean'];
  timestamp: Maybe<Scalars['Float']>;
  primary: Scalars['String'];
  secondary: Scalars['String'];
  /** Blog post link */
  href: Scalars['String'];
  header: Scalars['String'];
  body: Scalars['String'];
  img: Scalars['String'];
  minVersion: Scalars['String'];
};

export type NotificationReleaseAdd = {
  __typename: 'NotificationReleaseAdd';
  success: Scalars['Boolean'];
};

export type NotificationReleaseInput = {
  userId: Scalars['String'];
  primary: Scalars['String'];
  secondary: Scalars['String'];
  /** Blog post link */
  href: Scalars['String'];
  header: Scalars['String'];
  body: Scalars['String'];
  img: Scalars['String'];
  minVersion: Scalars['String'];
};

/** Soil Type Populated Notification */
export type NotificationSoilTypePopulate = Notification & {
  __typename: 'NotificationSoilTypePopulate';
  id: Scalars['String'];
  read: Scalars['Boolean'];
  timestamp: Maybe<Scalars['Float']>;
  sensorId: Scalars['String'];
  sensorName: Scalars['String'];
  /** The name of the auto populated soil type */
  soilType: Scalars['String'];
};

export type NotificationSoilTypePopulateAdd = {
  __typename: 'NotificationSoilTypePopulateAdd';
  success: Scalars['Boolean'];
};

export type NotificationSoilTypePopulateInput = {
  userId: Scalars['String'];
  timestamp: Scalars['Float'];
  sensorId: Scalars['String'];
  soilType: Scalars['String'];
  sensorName: Scalars['String'];
};

/** An order request */
export type Order = {
  __typename: 'Order';
  approved: Scalars['Boolean'];
  details: OrderDetails;
  id: Scalars['Int'];
  notes: Maybe<Scalars['String']>;
  placedTimestamp: Scalars['Float'];
  prepaid: Maybe<OrderPrepaid>;
  boundIds: Array<Scalars['String']>;
  sharingEmails: Array<Scalars['String']>;
  status: OrderStatus;
  totalPrice: Scalars['Float'];
  unboundHardware: Array<OrderUnboundHardware>;
  zohoSalesOrder: Maybe<ZohoSalesOrder>;
  managedServiceOptions: Maybe<ManagedServiceOptions>;
  shipments: Array<OrderShipment>;
  unshippedHardware: Array<OrderDetailsHardware>;
};

export type OrderDetails = {
  __typename: 'OrderDetails';
  address: Maybe<Address>;
  hardware: Array<OrderDetailsHardware>;
  customer: Maybe<OrderDetailsCustomerCopy>;
  rep: Maybe<OrderDetailsRepCopy>;
};

export type OrderDetailsCustomerCopy = {
  __typename: 'OrderDetailsCustomerCopy';
  id: Scalars['String'];
  email: Maybe<Scalars['String']>;
  name: Maybe<Name>;
  phone: Maybe<Scalars['String']>;
};

export type OrderDetailsHardware = {
  __typename: 'OrderDetailsHardware';
  id: Maybe<Scalars['String']>;
  model: Model;
  pricePerUnit: Scalars['Float'];
  type: HardwareTypeEnum;
  quantity: Scalars['Int'];
  boundIds: Array<Scalars['String']>;
};

export type OrderDetailsRepCopy = {
  __typename: 'OrderDetailsRepCopy';
  id: Scalars['String'];
  email: Maybe<Scalars['String']>;
  name: Maybe<Name>;
  phone: Maybe<Scalars['String']>;
};

export type OrderPrepaid = {
  __typename: 'OrderPrepaid';
  plan: SensorDataPlan;
  months: Scalars['Int'];
  start: Scalars['Float'];
};

export type OrderShipment = {
  __typename: 'OrderShipment';
  id: Scalars['Int'];
  amount: Scalars['Float'];
  carrier: Scalars['String'];
  trackingNumber: Maybe<Scalars['String']>;
  deliveredDate: Maybe<Scalars['String']>;
  hardware: Array<OrderDetailsHardware>;
  boundIds: Array<Scalars['String']>;
};

export enum OrderStatus {
  pending = 'pending',
  processing = 'processing',
  shipped = 'shipped',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

export type OrderUnboundHardware = {
  __typename: 'OrderUnboundHardware';
  id: Scalars['String'];
  model: Model;
};

export type PerformanceStatistics = {
  __typename: 'PerformanceStatistics';
  percentOnline: Scalars['Float'];
  percentWarning: Scalars['Float'];
  percentOffline: Scalars['Float'];
  time: Scalars['Float'];
};

export type PerformanceStatisticsInput = {
  percentOnline: Scalars['Float'];
  percentWarning: Scalars['Float'];
  percentOffline: Scalars['Float'];
  time: Scalars['Float'];
};

export enum PermissionLevel {
  owner = 'owner',
  admin = 'admin',
  viewer = 'viewer',
}

export enum PermissionLevelInput {
  admin = 'admin',
  viewer = 'viewer',
}

export type PlanInformation = {
  __typename: 'PlanInformation';
  active: Maybe<Scalars['Boolean']>;
  email_update: Maybe<Scalars['Boolean']>;
  export_data: Maybe<Scalars['Boolean']>;
  text_update: Maybe<Scalars['Boolean']>;
};

/** Corresponds to popup modals on the client */
export enum PopupId {
  SensorManagementPlanRelease = 'SensorManagementPlanRelease',
  IrrigationRecommendationRelease = 'IrrigationRecommendationRelease',
  ChatSupportRelease = 'ChatSupportRelease',
}

export type PostPerformanceResponse = {
  __typename: 'PostPerformanceResponse';
  success: Scalars['Boolean'];
};

export type PressureNoSchedule = {
  __typename: 'PressureNoSchedule';
  /** This type doesn't need any extra information */
  void: Scalars['Boolean'];
};

export type PressureOffSchedule = {
  __typename: 'PressureOffSchedule';
  /** Whether or not the sensor is on schedule right now */
  currentlyOnSchedule: Scalars['Boolean'];
  /** List of times in the past 24 hours where the sensor was off schedule */
  offScheduleTimes: Array<PressureOffScheduleTime>;
};

export type PressureOffScheduleTime = {
  __typename: 'PressureOffScheduleTime';
  /** Time of issue in hh:mm a */
  time: Scalars['String'];
  /** Whether or not we expected the sensor to be on or off */
  shouldBeOn: Scalars['Boolean'];
  /** Explanation about off schedule */
  type: PressureOffScheduleType;
};

export enum PressureOffScheduleType {
  unexpected = 'unexpected',
  missed = 'missed',
  lowPressure = 'lowPressure',
  highPressure = 'highPressure',
}

export type PressureOnSchedule = {
  __typename: 'PressureOnSchedule';
  /** This type doesn't need any extra information */
  void: Scalars['Boolean'];
};

export type PressureScheduleResult =
  | PressureOnSchedule
  | PressureOffSchedule
  | PressureNoSchedule;

export type PressureSensorData = {
  __typename: 'PressureSensorData';
  currentPressure: CurrentPressureResult;
  onOffTimes: Array<SensorPressureOnOffData>;
  pressure: Array<SensorPressureData>;
};

export type PressureSensorDataResult = PressureSensorData | SensorNoPlan;

export type PressureSensorPlan = {
  __typename: 'PressureSensorPlan';
  timeZone: Scalars['String'];
  expectedPressure: Maybe<Scalars['Float']>;
  stages: Array<PressureSensorPlanStage>;
};

export type PressureSensorPlanInput = {
  sensorId: Scalars['String'];
  /** Time zone string e.g. America/New_York */
  timeZone: Scalars['String'];
  expectedPressure?: Maybe<Scalars['Float']>;
  stages: Array<PressureSensorPlanStageInput>;
};

export type PressureSensorPlanStage = {
  __typename: 'PressureSensorPlanStage';
  /** YYYY-MM-DD */
  startDate: Scalars['String'];
  onTimes: Array<PressureSensorPlanStageTime>;
};

export type PressureSensorPlanStageInput = {
  /** YYYY-MM-DD */
  startDate: Scalars['String'];
  onTimes: Array<PressureSensorPlanStageTimeInput>;
};

export type PressureSensorPlanStageTime = {
  __typename: 'PressureSensorPlanStageTime';
  /** Start time in minutes (0 is midnight) */
  start: Scalars['Float'];
  /** End time in minutes (0 is midnight) */
  end: Scalars['Float'];
};

export type PressureSensorPlanStageTimeInput = {
  /** Start time in minutes (0 is midnight) */
  start: Scalars['Float'];
  /** End time in minutes (0 is midnight) */
  end: Scalars['Float'];
};

export type PressureSensorUpdateInput = {
  name?: Maybe<Scalars['String']>;
  location?: Maybe<LocationInput>;
};

export type Pricing = {
  __typename: 'Pricing';
  id: Scalars['String'];
  name: Maybe<Scalars['String']>;
  hardware: Array<PricingHardware>;
};

export type PricingHardware = {
  __typename: 'PricingHardware';
  modelId: Scalars['String'];
  prices: Array<PricingHardwarePrices>;
};

export type PricingHardwarePrices = {
  __typename: 'PricingHardwarePrices';
  /** If someone buys >= this amount, they get the discounted price */
  quantity: Scalars['Int'];
  price: Scalars['Float'];
};

/** Trellis queries */
export type Query = {
  __typename: 'Query';
  /** Get info of a field */
  field: Maybe<Field>;
  /** Check a users activation progress */
  activationProgress: ActivationProgressResponse;
  /** Get info of a sensor */
  sensor: Maybe<Sensor>;
  weatherStation: TrellisWeatherSensor;
  pressureSensor: TrellisPressureSensor;
  irrigationSystem: IrrigationSystem;
  /** Get all sensors */
  allSensors: Array<Sensor>;
  /** Get all weather stations */
  allWeatherStations: Array<TrellisWeatherSensor>;
  /** Get all irrigation systems */
  irrigationSystems: Array<IrrigationSystem>;
  /** Get info of a gateway */
  gateway: Maybe<Gateway>;
  /** Get all gateways */
  allGateways: Array<Gateway>;
  /** Get the info of the logged in user. */
  me: User;
  /** Get a user by id */
  user: Maybe<User>;
  /** Get a distributor by id */
  distributor: Maybe<Distributor>;
  /** Get all dashboard users. */
  allUsers: Array<User>;
  /** Get all fields */
  allFields: Array<Field>;
  /** Get all crop types */
  crops: Array<Crop>;
  /** Get all soil types (Trellis defaults + User defined) */
  soils: Array<Soil>;
  /** Get an single soil type */
  soil: Maybe<Soil>;
  /** Get all irrigation types */
  irrigationTypes: Array<Irrigation>;
  /** Get distributor portal for the logged in user. */
  currentDistributor: Distributor;
  /** Get all distributors */
  allDistributors: Array<Distributor>;
  /** Get a rep of currentDistributor */
  rep: Maybe<Distributor>;
  /** Get an order */
  order: Maybe<Order>;
  /** Get all Open Orders */
  openOrders: Array<Order>;
  /** Get all Orders */
  allOrders: Array<Order>;
  /** Get a customer */
  customer: Maybe<Customer>;
  /** Get the list of pricings */
  pricings: Array<Pricing>;
  /** Get the activation url for a user/distributor */
  activationUrl: Maybe<Scalars['String']>;
  /** Get an archived sensor */
  archivedSensor: Maybe<ArchivedSensor>;
  /** Get an archived gateway */
  archivedGateway: Maybe<ArchivedGateway>;
  /** Get info of an archived field */
  archivedField: Maybe<ArchivedField>;
  /** Get info of an archived irrigation system */
  archivedIrrigationSystem: ArchivedIrrigationSystem;
  archivedPressureSensor: ArchivedTrellisPressureSensor;
  /** Get all archived sensors */
  archivedSensors: Array<ArchivedSensor>;
  /** Sales Rep information */
  salesRep: Maybe<SalesRep>;
  /** All Sales Reps */
  salesReps: Array<SalesRep>;
  /** Get soil type from lat/lng (using Web Soil Survey) */
  soilTypeFromLocation: Maybe<Scalars['String']>;
  /** Daily performance statistics for all of the hardware */
  performanceStatistics: Array<PerformanceStatistics>;
  sensorManagementPlan: Maybe<SensorManagementPlan>;
  gatewaySoftwareVersions: Array<SoftwareVersion>;
  gatewayFlashLibrary: Array<FlashLibraryEntry>;
  gatewaySimcomLibrary: Array<Scalars['String']>;
  sensorSoftwareVersions: Array<SoftwareVersion>;
  sensorFlashLibrary: Array<FlashLibraryEntry>;
  averageUsageStats: Maybe<UsageStats>;
  /** Get soil type from lat/lng (using Web Soil Survey) */
  fieldBoundaryInfo: Maybe<FieldBoundaryInfoResult>;
  salesRepForCustomer: Maybe<SalesRep>;
  supportChatLog: SupportChatLog;
};

/** Trellis queries */
export type QueryfieldArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryactivationProgressArgs = {
  input: ActivationProgressInput;
};

/** Trellis queries */
export type QuerysensorArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryweatherStationArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QuerypressureSensorArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryirrigationSystemArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QueryallSensorsArgs = {
  onlyRG: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QuerygatewayArgs = {
  id: Maybe<Scalars['String']>;
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryuserArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QuerydistributorArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QuerysoilArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QueryrepArgs = {
  repId: Scalars['String'];
};

/** Trellis queries */
export type QueryorderArgs = {
  orderId: Scalars['Int'];
};

/** Trellis queries */
export type QuerycustomerArgs = {
  customerId: Maybe<Scalars['String']>;
  includeZoho: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryactivationUrlArgs = {
  distributor: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
};

/** Trellis queries */
export type QueryarchivedSensorArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryarchivedGatewayArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryarchivedFieldArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QueryarchivedIrrigationSystemArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryarchivedPressureSensorArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryarchivedSensorsArgs = {
  onlyRG: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QuerysalesRepArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QuerysoilTypeFromLocationArgs = {
  location: NNLocationInput;
};

/** Trellis queries */
export type QuerysensorManagementPlanArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QueryfieldBoundaryInfoArgs = {
  input: FieldBoundaryInfoInput;
};

/** Trellis queries */
export type QuerysalesRepForCustomerArgs = {
  email: Scalars['String'];
};

export type RegisterUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  userId: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
  textEnabled: Scalars['Boolean'];
  emailEnabled: Scalars['Boolean'];
  termsCheckbox: Scalars['Boolean'];
};

export type RegistrationMutationResponse = {
  __typename: 'RegistrationMutationResponse';
  success: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type RemoveCustomerInput = {
  /** ID of customer to remove */
  customerId: Scalars['String'];
};

export type RemoveCustomerResponse = {
  __typename: 'RemoveCustomerResponse';
  /** The id of the removed customer */
  id: Maybe<Scalars['String']>;
};

export type RenewManagedServiceInput = {
  orderId: Scalars['Int'];
};

export type RenewManagedServiceResponse = {
  __typename: 'RenewManagedServiceResponse';
  success: Scalars['Boolean'];
};

export type SalesRep = {
  __typename: 'SalesRep';
  id: Scalars['String'];
  email: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  commissionStructure: Array<CommissionStructure>;
  orders: Array<SalesRepOrder>;
  /** Calculate commission for a sales rep over a period */
  periodCommission: Maybe<Scalars['Float']>;
};

export type SalesRepperiodCommissionArgs = {
  periodStart: Maybe<Scalars['Float']>;
  periodEnd: Maybe<Scalars['Float']>;
};

export type SalesRepOrder = {
  __typename: 'SalesRepOrder';
  amount: Scalars['Float'];
  cost: Scalars['Float'];
  time: Scalars['Float'];
  source: Scalars['String'];
  id: Scalars['String'];
};

export type SendSupportMessageResponse = {
  __typename: 'SendSupportMessageResponse';
  success: Scalars['Boolean'];
  supportChatLog: SupportChatLog;
};

export type SendVerificationEmailInput = {
  email: Scalars['String'];
};

export type Sensor = Hardware &
  SensorBase & {
    __typename: 'Sensor';
    /**
     * Whether or not the sensor is active.  Controls alerts, visibility on
     * dashboard, etc... Set true when the sensor's data is paid for, and false when
     * the sensor is archived.
     */
    active: Scalars['Boolean'];
    alerts: SensorAlerts;
    battery: Maybe<Scalars['Float']>;
    canDownloadData: Scalars['Boolean'];
    configuration: Maybe<SensorConfiguration>;
    configurationUpdated: Maybe<Scalars['Float']>;
    crop_type: Maybe<Crop>;
    crop_variety: Maybe<Scalars['String']>;
    current_state: Maybe<Scalars['Int']>;
    data: Maybe<Array<SensorData>>;
    dataView: Maybe<SensorDataView>;
    current_tension: Maybe<Scalars['Int']>;
    errors: Array<SensorData>;
    field: Maybe<Field>;
    gateway: Maybe<Gateway>;
    gatewayId: Maybe<Scalars['String']>;
    /** Format: YYYY-MM-DD */
    harvest_date: Maybe<Scalars['String']>;
    hasDataAccess: Scalars['Boolean'];
    userHasSensorAccess: Scalars['Boolean'];
    id: Scalars['String'];
    /** Used to prevent hw from showing up on the dist portal */
    inactive: Scalars['Boolean'];
    location: Maybe<Location>;
    gpsUpdated: Maybe<Scalars['Float']>;
    gpsLocked: Maybe<Scalars['Boolean']>;
    managementPlan: Maybe<SensorManagementPlan>;
    /**
     * A list of the users sensor management plans that have
     * compatible models
     */
    compatibleManagementPlans: Array<SensorManagementPlan>;
    /** Is the sensor online or offline? */
    connectionStatus: ConnectionStatus;
    /**
     * If the sensor has a management plan, this will be
     * the current stage
     */
    currentStage: Maybe<SensorManagementPlanStage>;
    model: Maybe<Model>;
    moistureMethod: Maybe<MoistureMethod>;
    name: Scalars['String'];
    order: Maybe<Order>;
    owner: Maybe<HardwareUser>;
    isOwner: Scalars['Boolean'];
    permissionLevel: Maybe<PermissionLevel>;
    persistence: Maybe<Scalars['Float']>;
    /**
     * If the stripeSubActiveUntil is in the future, this will be it
     * This will be set regardless of if the sensor is cancelled or not
     */
    planActiveUntil: Maybe<Scalars['Float']>;
    /**
     * If stripeSubCancelledAt is in the future, this will be it
     * This should be set only if the sensor is scheduled to be cancelled at the end of the cycle
     */
    planCancelDate: Maybe<Scalars['Float']>;
    /** The currently selected plan for the sensor OR null if the sensor does not have an active subscription */
    activePlan: Maybe<SensorDataPlan>;
    /** The currently selected plan for the sensor */
    plan: Maybe<SensorDataPlan>;
    /** Stripe plan ID. Use "plan" for frontend stuff. */
    planId: Maybe<StripeDataPlan>;
    /** Whether or not this piece of hardware needs a plan to access data */
    planRequired: Scalars['Boolean'];
    plant_date: Maybe<Scalars['String']>;
    rssi: Maybe<Scalars['Float']>;
    sensor_time: Maybe<Scalars['Float']>;
    serviceLogs: Array<ServiceLog>;
    sharingMaxUsers: Scalars['Int'];
    soil_type: Maybe<Soil>;
    /** List of warnings for sensor */
    statusWarnings: Array<HardwareWarningEnum>;
    /** Returns true if the sensors has had at least 1 rain gauge message */
    hasRainfallData: Scalars['Boolean'];
    /** Returns true if this model of sensor has a rain gauge */
    hasRainGauge: Scalars['Boolean'];
    usage_stats: Maybe<UsageStats>;
    users: Array<HardwareUser>;
    weather: Weather;
  };

export type SensordataArgs = {
  limit: Maybe<Scalars['Int']>;
  start: Maybe<Scalars['Float']>;
  end: Maybe<Scalars['Float']>;
};

export type SensordataViewArgs = {
  start: Maybe<Scalars['Float']>;
  end: Maybe<Scalars['Float']>;
};

export type SensorAlerts = {
  __typename: 'SensorAlerts';
  dry: Maybe<Scalars['Float']>;
};

/** Class that contains fields for both Sensor and ArchivedSensor */
export type SensorBase = {
  canDownloadData: Scalars['Boolean'];
  crop_type: Maybe<Crop>;
  crop_variety: Maybe<Scalars['String']>;
  dataView: Maybe<SensorDataView>;
  field: Maybe<Field>;
  harvest_date: Maybe<Scalars['String']>;
  hasDataAccess: Scalars['Boolean'];
  hasRainfallData: Scalars['Boolean'];
  userHasSensorAccess: Scalars['Boolean'];
  id: Scalars['String'];
  location: Maybe<Location>;
  managementPlan: Maybe<SensorManagementPlan>;
  model: Maybe<Model>;
  moistureMethod: Maybe<MoistureMethod>;
  name: Scalars['String'];
  owner: Maybe<HardwareUser>;
  users: Array<HardwareUser>;
  isOwner: Scalars['Boolean'];
  permissionLevel: Maybe<PermissionLevel>;
  plan: Maybe<SensorDataPlan>;
  plant_date: Maybe<Scalars['String']>;
  sharingMaxUsers: Scalars['Int'];
  soil_type: Maybe<Soil>;
  hasRainGauge: Scalars['Boolean'];
  weather: Weather;
};

export type SensorConfiguration = {
  __typename: 'SensorConfiguration';
  ack_timeout: Maybe<Scalars['Float']>;
  boot: Maybe<Scalars['Boolean']>;
  freq: Maybe<Scalars['Float']>;
  gps_enable: Maybe<Scalars['Boolean']>;
  hibernate_enable: Maybe<Scalars['Boolean']>;
  historical_enable: Maybe<Scalars['Boolean']>;
  hw_ver: Maybe<Scalars['String']>;
  missed_message_sleep: Maybe<Scalars['Float']>;
  rpt_int: Maybe<Scalars['Float']>;
  sensor_var: Maybe<Scalars['String']>;
  sw_ver: Maybe<Scalars['String']>;
  t_sleep: Maybe<Scalars['Float']>;
  tx_high_threshold: Maybe<Scalars['Float']>;
  tx_low_threshold: Maybe<Scalars['Float']>;
  tx_power: Maybe<Scalars['Float']>;
};

export type SensorData = {
  __typename: 'SensorData';
  adc: Maybe<Array<Maybe<Scalars['Float']>>>;
  counters: Maybe<Array<Maybe<Scalars['Float']>>>;
  cumulativeCount: Maybe<Scalars['Float']>;
  vic: Maybe<Array<Maybe<Scalars['Float']>>>;
  gateway: Maybe<Scalars['String']>;
  meas_no: Maybe<Scalars['Float']>;
  moistures: Maybe<Array<Maybe<Scalars['Float']>>>;
  node: Maybe<Scalars['String']>;
  old_server_time: Maybe<Scalars['Float']>;
  /** If there is an error message sent for this serial number, query for it. */
  error: Maybe<Scalars['String']>;
  /** The raw data object (stringified).  Useful for prototyping stuff, don't use in prod. */
  raw: Scalars['String'];
  rainfall: Maybe<Array<SensorDataRainfall>>;
  rssi: Maybe<Scalars['Float']>;
  rtc_time: Maybe<Scalars['String']>;
  tcount: Maybe<Array<Maybe<Scalars['Float']>>>;
  bootCounter: Maybe<Scalars['Float']>;
  bootLimitCounter: Maybe<Scalars['Float']>;
  sessionRetry: Maybe<Scalars['Float']>;
  sessionFail: Maybe<Scalars['Float']>;
  historicalMessages: Maybe<Scalars['Float']>;
  pwrOnRstCounter: Maybe<Scalars['Float']>;
  brnOutRstCounter: Maybe<Scalars['Float']>;
  extRstCounter: Maybe<Scalars['Float']>;
  wdtRstCounter: Maybe<Scalars['Float']>;
  temp: Maybe<Array<Scalars['Float']>>;
  tensions: Maybe<Array<Maybe<Scalars['Float']>>>;
  tensionsWithTemp: Maybe<Array<Maybe<Scalars['Float']>>>;
  pressures: Maybe<Array<Scalars['Float']>>;
  voltages: Maybe<Array<Scalars['Float']>>;
  /** Pressure in psi */
  averagePressure: Maybe<Scalars['Float']>;
  /** Voltage in mV */
  averageVoltage: Maybe<Scalars['Float']>;
  /** Runtime in seconds */
  runtime: Maybe<Scalars['Float']>;
  time: Scalars['Float'];
  vin: Maybe<Scalars['Float']>;
};

export enum SensorDataPlan {
  active = 'active',
}

export type SensorDataRainfall = {
  __typename: 'SensorDataRainfall';
  time: Scalars['Float'];
  /** Hourly rainfall in inches */
  amount: Scalars['Float'];
};

export type SensorDataView = {
  __typename: 'SensorDataView';
  soil: Array<SensorSoilData>;
  rainfall: Array<SensorRainfallData>;
  weather: Array<WeatherStationData>;
  pressure: Array<SensorPressureData>;
};

export type SensorDataViewrainfallArgs = {
  tzoffset?: Maybe<Scalars['Int']>;
  limit: Maybe<Scalars['Int']>;
};

export type SensorEditInput = {
  sensorId: Scalars['String'];
  location: Scalars['String'];
  value: Scalars['String'];
  name: Scalars['String'];
};

export type SensorEditResponse = {
  __typename: 'SensorEditResponse';
  success: Scalars['Boolean'];
};

/**
 * A plan for how to interpret data from a sensor
 * for the duration of a season
 */
export type SensorManagementPlan = {
  __typename: 'SensorManagementPlan';
  id: Scalars['String'];
  name: Scalars['String'];
  model: Maybe<Model>;
  stages: Array<SensorManagementPlanStage>;
};

export type SensorManagementPlanAddResponse = {
  __typename: 'SensorManagementPlanAddResponse';
  success: Scalars['Boolean'];
  sensorManagementPlan: SensorManagementPlan;
  sensor: Sensor;
};

export type SensorManagementPlanInput = {
  name: Scalars['String'];
  stages: Array<SensorManagementPlanStageInput>;
};

export type SensorManagementPlanResponse = {
  __typename: 'SensorManagementPlanResponse';
  success: Scalars['Boolean'];
  sensorManagementPlan: Maybe<SensorManagementPlan>;
};

/**
 * A representation of how a sensor's data should
 * be interpreted for a window of time
 */
export type SensorManagementPlanStage = {
  __typename: 'SensorManagementPlanStage';
  name: Scalars['String'];
  wetTH: Scalars['Float'];
  dryTH: Scalars['Float'];
  weights: Array<Scalars['Float']>;
  DAP: Scalars['Int'];
};

export type SensorManagementPlanStageInput = {
  name: Scalars['String'];
  wetTH: Scalars['Float'];
  dryTH: Scalars['Float'];
  weights: Array<Scalars['Float']>;
  DAP: Scalars['Int'];
};

export type SensorMutationResponse = {
  __typename: 'SensorMutationResponse';
  /** The id of the sensor modified */
  sensorId: Maybe<Scalars['String']>;
  sensor: Maybe<Sensor>;
  success: Scalars['Boolean'];
};

export type SensorNoPlan = {
  __typename: 'SensorNoPlan';
  message: Scalars['String'];
};

export type SensorOffline = {
  __typename: 'SensorOffline';
  message: Scalars['String'];
};

export type SensorPlanDetails = {
  __typename: 'SensorPlanDetails';
  /** If the sensor is prepaid, it's subscription start date might be in the future */
  activeAfter: Maybe<Scalars['Float']>;
  /**
   * If the stripeSubActiveUntil is in the future, this will be it
   * This will be set regardless of if the sensor is cancelled or not
   */
  activeUntil: Maybe<Scalars['Float']>;
  /**
   * If stripeSubCancelledAt is in the future, this will be it
   * This should be set only if the sensor is scheduled to be cancelled at the end of the cycle
   */
  cancelDate: Maybe<Scalars['Float']>;
  /** True if the sensor has an active data plan */
  active: Scalars['Boolean'];
};

export type SensorPressureData = {
  __typename: 'SensorPressureData';
  /** Time in unix ms */
  t: Scalars['Float'];
  /** Average pressure in psi */
  avgP: Scalars['Float'];
  /** Runtime in seconds (0-120) */
  rt: Scalars['Float'];
};

export type SensorPressureOnOffData = {
  __typename: 'SensorPressureOnOffData';
  /** Time in unix ms (start of change) */
  t: Scalars['Float'];
  /** Whether this is turning on, off, offline */
  state: SensorPressureOnOffDataState;
  /** Average pressure in psi during on/off */
  avgP: Scalars['Float'];
};

export enum SensorPressureOnOffDataState {
  on = 'on',
  off = 'off',
  offline = 'offline',
}

export type SensorRainfallData = {
  __typename: 'SensorRainfallData';
  /** Start of rainfall bucket.  Format: YYYY-MM-DD */
  t: Scalars['String'];
  /** Amount of rainfall in inches */
  a: Scalars['Float'];
};

export type SensorReprogramInput = {
  sensorId: Scalars['String'];
  softwareVersion: Scalars['String'];
  hardwareVersion: Scalars['String'];
  variant: Scalars['String'];
  name: Scalars['String'];
};

export type SensorReprogramResponse = {
  __typename: 'SensorReprogramResponse';
  success: Scalars['Boolean'];
};

export type SensorResetInput = {
  sensorId: Scalars['String'];
  name: Scalars['String'];
};

export type SensorResetResponse = {
  __typename: 'SensorResetResponse';
  success: Scalars['Boolean'];
};

export type SensorRetryInput = {
  sensorId: Scalars['String'];
  measurement: Scalars['String'];
  name: Scalars['String'];
};

export type SensorRetryResponse = {
  __typename: 'SensorRetryResponse';
  success: Scalars['Boolean'];
};

export type SensorSoilData = {
  __typename: 'SensorSoilData';
  /** Time in unix ms */
  t: Scalars['Float'];
  /** Soil moisture tensions */
  sm: Array<Maybe<Scalars['Float']>>;
  /** Soil moisture tensions with temp */
  smt: Array<Maybe<Scalars['Float']>>;
  /** Soil temperature */
  st: Array<Maybe<Scalars['Float']>>;
  /** Volumetric Ion Content of soil */
  vic: Array<Maybe<Scalars['Float']>>;
  /** VIC converted to EC in dS/m */
  ec: Array<Maybe<Scalars['Float']>>;
};

export type SensorWeight = {
  __typename: 'SensorWeight';
  id: Scalars['String'];
  weight: Scalars['Int'];
};

export type SensorWeightInput = {
  id: Scalars['String'];
  weight: Scalars['Int'];
};

export type ServiceLog = {
  __typename: 'ServiceLog';
  /** Key of the log in firebase */
  id: Scalars['String'];
  /** Author of service log */
  name: Scalars['String'];
  /** Date the service happened */
  date: Scalars['Float'];
  /** Location of the service */
  location: Scalars['String'];
  odometerTo: Scalars['Float'];
  odometerFrom: Scalars['Float'];
  /** Time spent servicing this hardware */
  timeSpent: Scalars['String'];
  /** Reason for the service */
  reason: Scalars['String'];
  /** Result of the service */
  result: Scalars['String'];
  /** Follow up tasks for after the service */
  followUpTasks: Scalars['String'];
};

export type ServiceLogCreateInput = {
  /** Id of the hardware */
  hw: Scalars['String'];
  /** Type of hardware, either gateway or sensor */
  type: Scalars['String'];
  /** Author of service log */
  name: Scalars['String'];
  /** Date the service happened */
  date: Scalars['Float'];
  /** Location of the service */
  location: Scalars['String'];
  odometerTo: Scalars['Float'];
  odometerFrom: Scalars['Float'];
  /** Time spent servicing this hardware */
  timeSpent: Scalars['String'];
  /** Reason for the service */
  reason: Scalars['String'];
  /** Result of the service */
  result: Scalars['String'];
  /** Follow up tasks for after the service */
  followUpTasks: Scalars['String'];
};

export type ServiceLogCreateReponse = {
  __typename: 'ServiceLogCreateReponse';
  /** If the creation succeeded */
  success: Scalars['Boolean'];
  /** If creation succeeded, the log */
  log: ServiceLog;
};

export type ServiceLogDeleteInput = {
  /** Key of the service log */
  id: Scalars['String'];
  /** Id of the hardware */
  hw: Scalars['String'];
  /** Type of hardware, either gateway or sensor */
  type: Scalars['String'];
};

export type ServiceLogDeleteReponse = {
  __typename: 'ServiceLogDeleteReponse';
  /** If the delete succeeded */
  success: Scalars['Boolean'];
};

export type ServiceLogUpdateInput = {
  /** Key of the service log */
  id: Scalars['String'];
  /** Id of the hardware */
  hw: Scalars['String'];
  /** Type of hardware, either gateway or sensor */
  type: Scalars['String'];
  /** Author of service log */
  name: Scalars['String'];
  /** Date the service happened */
  date: Scalars['Float'];
  /** Location of the service */
  location: Scalars['String'];
  odometerTo: Scalars['Float'];
  odometerFrom: Scalars['Float'];
  /** Time spent servicing this hardware */
  timeSpent: Scalars['String'];
  /** Reason for the service */
  reason: Scalars['String'];
  /** Result of the service */
  result: Scalars['String'];
  /** Follow up tasks for after the service */
  followUpTasks: Scalars['String'];
};

export type ServiceLogUpdateReponse = {
  __typename: 'ServiceLogUpdateReponse';
  /** If the update succeeded */
  success: Scalars['Boolean'];
  /** If update succeeded, the log */
  log: ServiceLog;
};

export type ShipOrderInput = {
  /** The id of the order to mark as shpped */
  orderId: Scalars['Int'];
  /** Tracking number of the shipment (required) */
  trackingNumber: Scalars['String'];
  /** Carrier being used (USPS) */
  carrier: Scalars['String'];
  /** Shipping cost in USD */
  amount: Scalars['Float'];
  /** Whether or not to send shipping email */
  sendShippingEmail: Scalars['Boolean'];
};

export type ShipOrderResponse = {
  __typename: 'ShipOrderResponse';
  /** The id of the order that was marked as shipped */
  id: Maybe<Scalars['Int']>;
};

export type SoftwareVersion = {
  __typename: 'SoftwareVersion';
  name: Scalars['String'];
  hardwareVersions: Array<HardwareVersions>;
};

export type Soil = {
  __typename: 'Soil';
  id: Scalars['String'];
  name: Scalars['String'];
  /** Tension threshold beneath which soil is considered dry */
  t_dry: Scalars['Float'];
  /** Tension threshold above which soil is considered wet */
  t_wet: Scalars['Float'];
  /** Capacitance threshold beneath which soil is considered dry */
  c_dry: Scalars['Float'];
  /** Capacitance threshold above which soil is considered wet */
  c_wet: Scalars['Float'];
  /** True if a user created the soil type */
  custom: Scalars['Boolean'];
  clay_percent: Scalars['Float'];
};

export type StartManagedServiceInput = {
  orderId: Scalars['Int'];
};

export type StartManagedServiceResponse = {
  __typename: 'StartManagedServiceResponse';
  success: Scalars['Boolean'];
};

export type StripeCard = {
  __typename: 'StripeCard';
  id: Scalars['String'];
  brand: Maybe<Scalars['String']>;
  last4: Maybe<Scalars['String']>;
};

export enum StripeDataPlan {
  TrellisDashboardPrepaid = 'TrellisDashboardPrepaid',
  TrellisDashboard = 'TrellisDashboard',
  TrellisDashboard15 = 'TrellisDashboard15',
  TrellisDashboard25 = 'TrellisDashboard25',
  TrellisDashboard30 = 'TrellisDashboard30',
  TrellisDashboard25Volume = 'TrellisDashboard25Volume',
}

export type StripeSubscription = {
  __typename: 'StripeSubscription';
  id: Scalars['String'];
  canceled: Scalars['Boolean'];
  periodEnd: Scalars['Float'];
};

export type Subscription = {
  __typename: 'Subscription';
  /**
   * In the future, we may return a SupportChatLog here so the client doesn't need to refresh.
   * Boolean acts as a placeholder.
   */
  updateSupportChat: Scalars['Boolean'];
};

export type SupportChatLog = {
  __typename: 'SupportChatLog';
  messages: Array<SupportMessage>;
  closed: Scalars['Boolean'];
};

export type SupportMessage = {
  __typename: 'SupportMessage';
  from: Scalars['String'];
  body: Scalars['String'];
  id: Scalars['String'];
  time: Scalars['String'];
};

export type TransferFundsManagedServiceInput = {
  /** ID of distributor to transfer funds to */
  distributorId: Scalars['String'];
  /** Amount in cents */
  amount: Scalars['Int'];
};

export type TransferFundsManagedServiceReponse = {
  __typename: 'TransferFundsManagedServiceReponse';
  success: Scalars['Boolean'];
};

export type TrellisPressureSensor = TrellisSensor & {
  __typename: 'TrellisPressureSensor';
  data: PressureSensorDataResult;
  plan: Maybe<PressureSensorPlan>;
  irrigationSystem: Maybe<IrrigationSystem>;
  id: Scalars['String'];
  name: Scalars['String'];
  /**
   * Whether or not the sensor is active.  Controls alerts, visibility on
   * dashboard, etc... Set true when the sensor's data is paid for, and false when
   * the sensor is archived.
   */
  active: Scalars['Boolean'];
  /** Is the sensor online or offline? */
  connectionStatus: ConnectionStatus;
  dataPlan: SensorPlanDetails;
  lastReportedTime: Maybe<Scalars['Float']>;
  location: Maybe<Location>;
  isOwner: Scalars['Boolean'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type TrellisSensor = {
  id: Scalars['String'];
  name: Scalars['String'];
  /**
   * Whether or not the sensor is active.  Controls alerts, visibility on
   * dashboard, etc... Set true when the sensor's data is paid for, and false when
   * the sensor is archived.
   */
  active: Scalars['Boolean'];
  /** Is the sensor online or offline? */
  connectionStatus: ConnectionStatus;
  dataPlan: SensorPlanDetails;
  lastReportedTime: Maybe<Scalars['Float']>;
  location: Maybe<Location>;
  isOwner: Scalars['Boolean'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type TrellisWeatherSensor = TrellisSensor & {
  __typename: 'TrellisWeatherSensor';
  data: WeatherSensorDataResult;
  managementPlan: Maybe<WeatherStationManagementPlan>;
  fields: Array<Field>;
  id: Scalars['String'];
  name: Scalars['String'];
  /**
   * Whether or not the sensor is active.  Controls alerts, visibility on
   * dashboard, etc... Set true when the sensor's data is paid for, and false when
   * the sensor is archived.
   */
  active: Scalars['Boolean'];
  /** Is the sensor online or offline? */
  connectionStatus: ConnectionStatus;
  dataPlan: SensorPlanDetails;
  lastReportedTime: Maybe<Scalars['Float']>;
  location: Maybe<Location>;
  isOwner: Scalars['Boolean'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type UpdateAlertHistoryInput = {
  /** ID of user to update */
  uid: Scalars['String'];
  /** Update the last login reminder time */
  loginReminderSent?: Maybe<Scalars['Boolean']>;
  /** Update the last weekly alert time */
  weeklySent?: Maybe<Scalars['Boolean']>;
  /** Update the last monthly alert time */
  monthlySent?: Maybe<Scalars['Boolean']>;
  /** Update the dyk used */
  dykId?: Maybe<Scalars['String']>;
  /** Update the last activation reminder time */
  activationReminderSent?: Maybe<Scalars['Boolean']>;
  /** Update the last irrigation needed alert time */
  irrigationNeeded?: Maybe<Scalars['Boolean']>;
};

export type UpdateAlertHistoryResponse = {
  __typename: 'UpdateAlertHistoryResponse';
  /** The id of the user updated */
  id: Maybe<Scalars['String']>;
};

/** A list of all gateways owned by the user with a plan. */
export type UpdateDataPlanGatewayInput = {
  /** ID of the gateway */
  id: Scalars['String'];
  /** Data plan, or null for none */
  plan?: Maybe<SensorDataPlan>;
};

export type UpdateDataPlanInput = {
  sensors?: Maybe<Array<UpdateDataPlanSensorInput>>;
  gateways?: Maybe<Array<UpdateDataPlanGatewayInput>>;
};

export type UpdateDataPlanResponse = {
  __typename: 'UpdateDataPlanResponse';
  /** The id of the user updated */
  id: Maybe<Scalars['String']>;
};

/** A list of all sensors owned by the user with a plan. */
export type UpdateDataPlanSensorInput = {
  /** ID of the sensor */
  id: Scalars['String'];
  /** Data plan, or null for none */
  plan?: Maybe<SensorDataPlan>;
};

export type UpdateFieldInput = {
  boundary?: Maybe<Array<NNLocationInput>>;
  boundarySoilStats?: Maybe<Array<FieldBoundaryInfoSoilStatInput>>;
  boundarySensorInfo?: Maybe<Array<FieldBoundaryInfoResultSensorInput>>;
  field_name?: Maybe<Scalars['String']>;
  /** New list of sensor ids */
  field_sensors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** New list of gateway ids */
  field_gateways?: Maybe<Array<Maybe<Scalars['String']>>>;
  field_notes?: Maybe<Scalars['String']>;
  irrigationMetadata?: Maybe<FieldInputIrrigationMetadata>;
  sensorWeights?: Maybe<Array<SensorWeightInput>>;
  /** Yield of this field */
  yield?: Maybe<YieldInput>;
};

export type UpdateFieldMutationResponse = {
  __typename: 'UpdateFieldMutationResponse';
  success: Scalars['Boolean'];
  field: Maybe<Field>;
};

export type UpdateGatewayInput = {
  id: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationInput>;
};

export type UpdateGatewayMutationResponse = {
  __typename: 'UpdateGatewayMutationResponse';
  success: Scalars['Boolean'];
  gateway: Maybe<Gateway>;
};

export type UpdateGatewaySharingInput = {
  gatewayId: Scalars['String'];
  /** The email of the user to share it with */
  email: Scalars['String'];
  /** The permission level to give the user, or null to remove the permission */
  level?: Maybe<PermissionLevelInput>;
};

export type UpdateGatewaySharingResponse = {
  __typename: 'UpdateGatewaySharingResponse';
  /** The id of the gateway modified */
  success: Scalars['Boolean'];
  /** ID of user that was shared with */
  sharedUid: Scalars['String'];
  gateway: Gateway;
};

export type UpdateGatewayStatusInput = {
  id: Scalars['String'];
  inactive: Scalars['Boolean'];
};

export type UpdateLoginInput = {
  id: Scalars['String'];
  timestamp: Scalars['Float'];
};

export type UpdateLoginResponse = {
  __typename: 'UpdateLoginResponse';
  /** The id of the login added */
  id: Maybe<Scalars['String']>;
};

export type UpdateSensorInput = {
  /** Format: YYYY-MM-DD */
  harvest_date?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  crop_type?: Maybe<Scalars['String']>;
  crop_variety?: Maybe<Scalars['String']>;
  /** If calling as a rep, this must be set */
  customerId?: Maybe<Scalars['String']>;
  soil_type?: Maybe<Scalars['String']>;
  plant_date?: Maybe<Scalars['String']>;
  location?: Maybe<LocationInput>;
  active?: Maybe<Scalars['Boolean']>;
  managementPlan?: Maybe<Scalars['String']>;
};

export type UpdateSensorSharingInput = {
  sensorId: Scalars['String'];
  /** The email of the user to share it with */
  email: Scalars['String'];
  /** The permission level to give the user, or null to remove the permission */
  level?: Maybe<PermissionLevelInput>;
};

export type UpdateSensorSharingResponse = {
  __typename: 'UpdateSensorSharingResponse';
  /** The id of the sensor modified */
  success: Scalars['Boolean'];
  /** ID of user that was shared with */
  sharedUid: Scalars['String'];
  sensor: Sensor;
};

export type UpdateSensorStateInput = {
  sensorId: Scalars['String'];
  state: Scalars['Int'];
  tension: Scalars['Float'];
};

export type UpdateSensorStatusInput = {
  inactive?: Maybe<Scalars['Boolean']>;
  battery?: Maybe<Scalars['Float']>;
  rssi?: Maybe<Scalars['Float']>;
  sensor_time?: Maybe<Scalars['Float']>;
  usage_stats?: Maybe<UsageStatsInput>;
};

export type UpdateSoilInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  t_wet?: Maybe<Scalars['Float']>;
  t_dry?: Maybe<Scalars['Float']>;
  c_wet?: Maybe<Scalars['Float']>;
  c_dry?: Maybe<Scalars['Float']>;
};

export type UpdateSoilResponse = {
  __typename: 'UpdateSoilResponse';
  success: Scalars['Boolean'];
  soil: Soil;
};

export type UpdateUserPreferencesInput = {
  textEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  triggerAlertThresholdHours?: Maybe<Scalars['Float']>;
};

export type UpdateUserPreferencesResponse = {
  __typename: 'UpdateUserPreferencesResponse';
  success: Scalars['Boolean'];
  /** The user that was updated */
  me: User;
};

export type UpdateUserProfileInput = {
  name?: Maybe<NameInput>;
};

export type UpdateUserProfileResponse = {
  __typename: 'UpdateUserProfileResponse';
  /** The id of the user updated */
  id: Maybe<Scalars['String']>;
};

export type UsageStats = {
  __typename: 'UsageStats';
  dry: Scalars['Float'];
  ok: Scalars['Float'];
  wet: Scalars['Float'];
  online: Scalars['Float'];
  offline: Scalars['Float'];
  logins: Scalars['Float'];
};

export type UsageStatsInput = {
  dry: Scalars['Float'];
  ok: Scalars['Float'];
  wet: Scalars['Float'];
  online: Scalars['Float'];
  offline: Scalars['Float'];
  logins: Scalars['Float'];
};

export type UsedDidYouKnows = {
  __typename: 'UsedDidYouKnows';
  id: Scalars['String'];
  timestamp: Scalars['Float'];
};

export type User = {
  __typename: 'User';
  /** Firebase email associated with uid */
  accountEmail: Maybe<Scalars['String']>;
  /** True if the user has been activated by their distributor */
  activated: Maybe<Scalars['Boolean']>;
  /** True if the user has finished activating their account */
  activationCompleted: Maybe<Scalars['Boolean']>;
  alert_history: Maybe<Alert>;
  archivedSensors: Array<ArchivedSensor>;
  archivedGateways: Array<ArchivedGateway>;
  archivedFields: Array<ArchivedField>;
  archivedIrrigationSystems: Array<ArchivedIrrigationSystem>;
  cards: Maybe<Array<Maybe<StripeCard>>>;
  /** Email used for email alerts */
  email: Maybe<Scalars['String']>;
  distributor: Maybe<Distributor>;
  fields: Array<Field>;
  gateways: Array<Gateway>;
  gatewayIds: Array<Scalars['String']>;
  hasAccountIssues: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  /** Past invoices */
  invoices: Maybe<Array<Invoice>>;
  logins: Array<Maybe<Scalars['String']>>;
  managedServiceStatuses: Array<ManagedServiceStatus>;
  name: Name;
  notifications: Maybe<Array<Notification>>;
  /** Phone number used for text alerts */
  phone: Maybe<Scalars['String']>;
  planID: Maybe<StripeDataPlan>;
  preferences: UserPreferences;
  irrigationSystems: Array<IrrigationSystem>;
  pressureSensors: Array<TrellisPressureSensor>;
  prepaid: Maybe<Scalars['Boolean']>;
  prepaidStart: Maybe<Scalars['Float']>;
  prepaidUntil: Maybe<Scalars['Float']>;
  pushToken: Maybe<Scalars['String']>;
  sensors: Array<Sensor>;
  sensorIds: Array<Scalars['String']>;
  stripeSubID: Maybe<Scalars['String']>;
  stripeSubscription: Maybe<StripeSubscription>;
  stripeID: Maybe<Scalars['String']>;
  /** Sensors which have not been assigned to a field for this user */
  unassignedSensors: Array<Sensor>;
  upcomingInvoice: Maybe<Invoice>;
  /** All of the user's management plans */
  weatherManagementPlans: Array<WeatherStationManagementPlan>;
  weatherStations: Array<TrellisWeatherSensor>;
};

export type UserfieldsArgs = {
  onlyOwned: Maybe<Scalars['Boolean']>;
  onlyAdmin: Maybe<Scalars['Boolean']>;
};

export type UsergatewaysArgs = {
  onlyOwned: Maybe<Scalars['Boolean']>;
  onlyAdmin: Maybe<Scalars['Boolean']>;
  onlyRG: Maybe<Scalars['Boolean']>;
  onlyAvailable: Maybe<Scalars['Boolean']>;
};

export type UsersensorsArgs = {
  onlyOwned: Maybe<Scalars['Boolean']>;
  onlyAdmin: Maybe<Scalars['Boolean']>;
  onlyEditable: Maybe<Scalars['Boolean']>;
  onlyAvailable: Maybe<Scalars['Boolean']>;
};

export type UserPreferences = {
  __typename: 'UserPreferences';
  /** How many hours before the dry threshold a user wants to be notified */
  triggerAlertThresholdHours: Scalars['Float'];
  emailAlerts: Maybe<Scalars['Boolean']>;
  textAlerts: Maybe<Scalars['Boolean']>;
};

export type UserSetPushTokenInput = {
  token: Scalars['String'];
};

export type UserSetPushTokenResponse = {
  __typename: 'UserSetPushTokenResponse';
  success: Scalars['Boolean'];
};

export type Weather = {
  __typename: 'Weather';
  weeklyForecast: Array<WeatherWeeklyForecast>;
  historical: Array<WeatherHistorical>;
};

export type WeatherHistorical = {
  __typename: 'WeatherHistorical';
  /** 1 Hour rainfall amount (in) */
  precipAmount: Scalars['Float'];
  /** Time in unix ms */
  time: Scalars['Float'];
  /** Humidity (%) (0 - 100) */
  hum: Maybe<Scalars['Float']>;
  /** UV Index */
  uvIndex: Maybe<Scalars['Float']>;
  /** High Temperature (F) */
  tempHigh: Maybe<Scalars['Float']>;
  /** Low Temperature (F) */
  tempLow: Maybe<Scalars['Float']>;
  /** Wind speed (mph) */
  windSpd: Maybe<Scalars['Float']>;
  /** Precipitation intensity */
  precipIntensity: Maybe<Scalars['Float']>;
  /** Cloud cover (%) (0 - 100) */
  cloudCover: Maybe<Scalars['Float']>;
  /** pressure */
  pressure: Maybe<Scalars['Float']>;
};

export type WeatherSensorData = {
  __typename: 'WeatherSensorData';
  currentWeather: CurrentWeatherResult;
  dailyRainfall: Array<SensorRainfallData>;
  weather: Array<WeatherStationData>;
};

export type WeatherSensorDatadailyRainfallArgs = {
  tzoffset?: Maybe<Scalars['Int']>;
  start: Maybe<Scalars['Float']>;
};

export type WeatherSensorDataweatherArgs = {
  start: Maybe<Scalars['Float']>;
};

export type WeatherSensorDataResult = WeatherSensorData | SensorNoPlan;

export type WeatherStationAlert = {
  __typename: 'WeatherStationAlert';
  type: WeatherStationAlertType;
  relationToThreshold: AboveOrBelow;
  threshold: Scalars['Float'];
};

export type WeatherStationAlertInput = {
  type: WeatherStationAlertType;
  relationToThreshold: AboveOrBelow;
  threshold: Scalars['Float'];
};

export enum WeatherStationAlertType {
  humidity = 'humidity',
  rainrate = 'rainrate',
  solar = 'solar',
  temperature = 'temperature',
  wetBulb = 'wetBulb',
  wind = 'wind',
}

export type WeatherStationData = {
  __typename: 'WeatherStationData';
  /** Time in unix ms */
  time: Scalars['Float'];
  /** Humidity (%) (0 - 100) */
  hum: Scalars['Float'];
  /** Max Wind Direction (N/NE/etc) */
  mWindDir: Scalars['String'];
  /** Max wind speed (mph) */
  mWindSpd: Scalars['Float'];
  /** Rain Count */
  rnCnt: Scalars['Float'];
  /** Rain rate (in/hr) */
  rnRate: Scalars['Float'];
  /** Rain total (in/hr) */
  rnTotal: Scalars['Float'];
  /** Solar radiation (W/m²) */
  solarRad: Scalars['Float'];
  /** Temperature (F) */
  temp: Scalars['Float'];
  /** Wet bulb temperature (F) */
  wbTemp: Maybe<Scalars['Float']>;
  /** Wind direction (N/NE/etc) */
  windDir: Scalars['String'];
  /** Wind speed (mph) */
  windSpd: Scalars['Float'];
  /** Wind error */
  windErr: Maybe<Scalars['Boolean']>;
  /** Rain rate error */
  rnRateErr: Maybe<Scalars['Boolean']>;
  /** Solar radiation error */
  solarRadErr: Maybe<Scalars['Boolean']>;
  /** Temperature error */
  tempErr: Maybe<Scalars['Boolean']>;
  /** Max wind error */
  mWindErr: Maybe<Scalars['Boolean']>;
  /** Humidity error */
  humErr: Maybe<Scalars['Boolean']>;
  /** Rain count error */
  rnCntErr: Maybe<Scalars['Boolean']>;
};

export type WeatherStationManagementPlan = {
  __typename: 'WeatherStationManagementPlan';
  id: Scalars['String'];
  name: Scalars['String'];
  alerts: Array<WeatherStationAlert>;
};

export type WeatherStationManagementPlanInput = {
  name: Scalars['String'];
  alerts: Array<WeatherStationAlertInput>;
};

export type WeatherStationManagementPlanResponse = {
  __typename: 'WeatherStationManagementPlanResponse';
  success: Scalars['Boolean'];
  weatherStationManagementPlan: Maybe<WeatherStationManagementPlan>;
};

/** Null values will be ignored */
export type WeatherStationUpdateInput = {
  name?: Maybe<Scalars['String']>;
  managementPlan?: Maybe<Scalars['String']>;
  location?: Maybe<LocationInput>;
};

export type WeatherStationUpdateResponse = {
  __typename: 'WeatherStationUpdateResponse';
  success: Scalars['Boolean'];
  weatherStation: TrellisWeatherSensor;
};

export type WeatherWeeklyForecast = {
  __typename: 'WeatherWeeklyForecast';
  /** 24 Hour rainfall amount (in) */
  precipAmount: Scalars['Float'];
  /** Probability of precipitation (100-0) */
  precipProbability: Scalars['Float'];
  icon: Maybe<Scalars['String']>;
  tempHigh: Scalars['Float'];
  tempLow: Scalars['Float'];
  /** Time in unix ms */
  time: Scalars['Float'];
};

/** Yield data entered during archiving process */
export type YieldData = {
  __typename: 'YieldData';
  actualYield: Maybe<YieldType>;
  targetYield: Maybe<YieldType>;
  performance: Maybe<YieldDataPerformance>;
};

export enum YieldDataPerformance {
  over = 'over',
  equal = 'equal',
  under = 'under',
}

export type YieldInput = {
  amount?: Maybe<Scalars['Float']>;
  units?: Maybe<YieldUnits>;
};

export type YieldType = {
  __typename: 'YieldType';
  amount: Maybe<Scalars['Float']>;
  units: Maybe<YieldUnits>;
};

export enum YieldUnits {
  lbs = 'lbs',
  bu = 'bu',
}

export type ZohoInvoice = {
  __typename: 'ZohoInvoice';
  due: Scalars['Float'];
  paymentUrl: Scalars['String'];
  status: ZohoInvoiceStatus;
};

export enum ZohoInvoiceStatus {
  paid = 'paid',
  sent = 'sent',
  draft = 'draft',
  overdue = 'overdue',
}

export type ZohoSalesOrder = {
  __typename: 'ZohoSalesOrder';
  invoice: Maybe<ZohoInvoice>;
};

export type IdentityQueryVariables = {};

export type IdentityQuery = {
  __typename: 'Query';
  currentDistributor: {
    __typename: 'Distributor';
    id: string;
    isDistributor: Maybe<boolean>;
    isAdmin: Maybe<boolean>;
  };
};

export type AnalyticsInfoQueryVariables = {};

export type AnalyticsInfoQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    accountEmail: Maybe<string>;
    name: { __typename: 'Name'; first: Maybe<string>; last: Maybe<string> };
  };
};

export type AllDistributorsQueryVariables = {};

export type AllDistributorsQuery = {
  __typename: 'Query';
  allDistributors: Array<{
    __typename: 'Distributor';
    id: string;
    email: Maybe<string>;
    phone: Maybe<string>;
    isRep: Maybe<boolean>;
    isDistributor: Maybe<boolean>;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
    reps: Array<{ __typename: 'DistributorRepCopy'; id: string }>;
    customers: Array<{
      __typename: 'Customer';
      id: string;
      repId: Maybe<string>;
    }>;
  }>;
};

export type DeleteDealerMutationVariables = {
  input: DeleteDealerInput;
};

export type DeleteDealerMutation = {
  __typename: 'Mutation';
  deleteDealer: { __typename: 'DeleteDealerResponse'; id: string };
};

export type UserNameQueryVariables = {};

export type UserNameQuery = {
  __typename: 'Query';
  currentDistributor: {
    __typename: 'Distributor';
    id: string;
    isRep: Maybe<boolean>;
    isDistributor: Maybe<boolean>;
    isAdmin: Maybe<boolean>;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
  };
};

export type AllUsersQueryVariables = {};

export type AllUsersQuery = {
  __typename: 'Query';
  allUsers: Array<{
    __typename: 'User';
    id: string;
    email: Maybe<string>;
    phone: Maybe<string>;
    name: { __typename: 'Name'; first: Maybe<string>; last: Maybe<string> };
    distributor: Maybe<{ __typename: 'Distributor'; id: string }>;
  }>;
};

export type ModelFieldsFragment = {
  __typename: 'Model';
  antennaLength: Maybe<number>;
  moistureDepths: Maybe<Array<number>>;
  temperatureDepths: Maybe<Array<number>>;
  ecDepths: Maybe<Array<number>>;
  hasWeatherStation: boolean;
  hasPressureSensor: boolean;
  hasRainGauge: boolean;
  moistureMethod: Maybe<MoistureMethod>;
  type: HardwareTypeEnum;
  probeless: boolean;
  custom: boolean;
};

export const ModelFieldsFragmentDoc = gql`
  fragment ModelFields on Model {
    antennaLength
    moistureDepths
    temperatureDepths
    ecDepths
    hasWeatherStation
    hasPressureSensor
    hasRainGauge
    moistureMethod
    type
    probeless
    custom
  }
`;
export const IdentityDocument = gql`
  query Identity {
    currentDistributor {
      id
      isDistributor
      isAdmin
    }
  }
`;
export type IdentityComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IdentityQuery,
    IdentityQueryVariables
  >,
  'query'
>;

export const IdentityComponent = (props: IdentityComponentProps) => (
  <ApolloReactComponents.Query<IdentityQuery, IdentityQueryVariables>
    query={IdentityDocument}
    {...props}
  />
);

/**
 * __useIdentityQuery__
 *
 * To run a query within a React component, call `useIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentityQuery({
 *   variables: {
 *   },
 * });
 */
export function useIdentityQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IdentityQuery,
    IdentityQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<IdentityQuery, IdentityQueryVariables>(
    IdentityDocument,
    baseOptions
  );
}
export function useIdentityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IdentityQuery,
    IdentityQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<IdentityQuery, IdentityQueryVariables>(
    IdentityDocument,
    baseOptions
  );
}
export type IdentityQueryHookResult = ReturnType<typeof useIdentityQuery>;
export type IdentityLazyQueryHookResult = ReturnType<
  typeof useIdentityLazyQuery
>;
export type IdentityQueryResult = ApolloReactCommon.QueryResult<
  IdentityQuery,
  IdentityQueryVariables
>;
export const AnalyticsInfoDocument = gql`
  query AnalyticsInfo {
    me {
      id
      accountEmail
      name {
        first
        last
      }
    }
  }
`;
export type AnalyticsInfoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AnalyticsInfoQuery,
    AnalyticsInfoQueryVariables
  >,
  'query'
>;

export const AnalyticsInfoComponent = (props: AnalyticsInfoComponentProps) => (
  <ApolloReactComponents.Query<AnalyticsInfoQuery, AnalyticsInfoQueryVariables>
    query={AnalyticsInfoDocument}
    {...props}
  />
);

/**
 * __useAnalyticsInfoQuery__
 *
 * To run a query within a React component, call `useAnalyticsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AnalyticsInfoQuery,
    AnalyticsInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AnalyticsInfoQuery,
    AnalyticsInfoQueryVariables
  >(AnalyticsInfoDocument, baseOptions);
}
export function useAnalyticsInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AnalyticsInfoQuery,
    AnalyticsInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AnalyticsInfoQuery,
    AnalyticsInfoQueryVariables
  >(AnalyticsInfoDocument, baseOptions);
}
export type AnalyticsInfoQueryHookResult = ReturnType<
  typeof useAnalyticsInfoQuery
>;
export type AnalyticsInfoLazyQueryHookResult = ReturnType<
  typeof useAnalyticsInfoLazyQuery
>;
export type AnalyticsInfoQueryResult = ApolloReactCommon.QueryResult<
  AnalyticsInfoQuery,
  AnalyticsInfoQueryVariables
>;
export const AllDistributorsDocument = gql`
  query AllDistributors {
    allDistributors {
      id
      email
      phone
      isRep
      isDistributor
      name {
        first
        last
      }
      reps {
        id
      }
      customers {
        id
        repId
      }
    }
  }
`;
export type AllDistributorsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllDistributorsQuery,
    AllDistributorsQueryVariables
  >,
  'query'
>;

export const AllDistributorsComponent = (
  props: AllDistributorsComponentProps
) => (
  <ApolloReactComponents.Query<
    AllDistributorsQuery,
    AllDistributorsQueryVariables
  >
    query={AllDistributorsDocument}
    {...props}
  />
);

/**
 * __useAllDistributorsQuery__
 *
 * To run a query within a React component, call `useAllDistributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDistributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDistributorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllDistributorsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllDistributorsQuery,
    AllDistributorsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AllDistributorsQuery,
    AllDistributorsQueryVariables
  >(AllDistributorsDocument, baseOptions);
}
export function useAllDistributorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllDistributorsQuery,
    AllDistributorsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllDistributorsQuery,
    AllDistributorsQueryVariables
  >(AllDistributorsDocument, baseOptions);
}
export type AllDistributorsQueryHookResult = ReturnType<
  typeof useAllDistributorsQuery
>;
export type AllDistributorsLazyQueryHookResult = ReturnType<
  typeof useAllDistributorsLazyQuery
>;
export type AllDistributorsQueryResult = ApolloReactCommon.QueryResult<
  AllDistributorsQuery,
  AllDistributorsQueryVariables
>;
export const DeleteDealerDocument = gql`
  mutation DeleteDealer($input: DeleteDealerInput!) {
    deleteDealer(input: $input) {
      id
    }
  }
`;
export type DeleteDealerMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDealerMutation,
  DeleteDealerMutationVariables
>;
export type DeleteDealerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteDealerMutation,
    DeleteDealerMutationVariables
  >,
  'mutation'
>;

export const DeleteDealerComponent = (props: DeleteDealerComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteDealerMutation,
    DeleteDealerMutationVariables
  >
    mutation={DeleteDealerDocument}
    {...props}
  />
);

/**
 * __useDeleteDealerMutation__
 *
 * To run a mutation, you first call `useDeleteDealerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDealerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDealerMutation, { data, loading, error }] = useDeleteDealerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDealerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDealerMutation,
    DeleteDealerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteDealerMutation,
    DeleteDealerMutationVariables
  >(DeleteDealerDocument, baseOptions);
}
export type DeleteDealerMutationHookResult = ReturnType<
  typeof useDeleteDealerMutation
>;
export type DeleteDealerMutationResult = ApolloReactCommon.MutationResult<
  DeleteDealerMutation
>;
export type DeleteDealerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDealerMutation,
  DeleteDealerMutationVariables
>;
export const UserNameDocument = gql`
  query UserName {
    currentDistributor {
      id
      isRep
      isDistributor
      isAdmin
      name {
        first
        last
      }
    }
  }
`;
export type UserNameComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    UserNameQuery,
    UserNameQueryVariables
  >,
  'query'
>;

export const UserNameComponent = (props: UserNameComponentProps) => (
  <ApolloReactComponents.Query<UserNameQuery, UserNameQueryVariables>
    query={UserNameDocument}
    {...props}
  />
);

/**
 * __useUserNameQuery__
 *
 * To run a query within a React component, call `useUserNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserNameQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserNameQuery,
    UserNameQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<UserNameQuery, UserNameQueryVariables>(
    UserNameDocument,
    baseOptions
  );
}
export function useUserNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserNameQuery,
    UserNameQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<UserNameQuery, UserNameQueryVariables>(
    UserNameDocument,
    baseOptions
  );
}
export type UserNameQueryHookResult = ReturnType<typeof useUserNameQuery>;
export type UserNameLazyQueryHookResult = ReturnType<
  typeof useUserNameLazyQuery
>;
export type UserNameQueryResult = ApolloReactCommon.QueryResult<
  UserNameQuery,
  UserNameQueryVariables
>;
export const AllUsersDocument = gql`
  query AllUsers {
    allUsers {
      id
      email
      phone
      name {
        first
        last
      }
      distributor {
        id
      }
    }
  }
`;
export type AllUsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllUsersQuery,
    AllUsersQueryVariables
  >,
  'query'
>;

export const AllUsersComponent = (props: AllUsersComponentProps) => (
  <ApolloReactComponents.Query<AllUsersQuery, AllUsersQueryVariables>
    query={AllUsersDocument}
    {...props}
  />
);

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllUsersQuery,
    AllUsersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllUsersQuery, AllUsersQueryVariables>(
    AllUsersDocument,
    baseOptions
  );
}
export function useAllUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllUsersQuery,
    AllUsersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(
    AllUsersDocument,
    baseOptions
  );
}
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<
  typeof useAllUsersLazyQuery
>;
export type AllUsersQueryResult = ApolloReactCommon.QueryResult<
  AllUsersQuery,
  AllUsersQueryVariables
>;
