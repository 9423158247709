import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip';

import GenericError from 'components/Errors/GenericError';
import Loading from 'components/Loading/Loading';

import { AllUsersQuery } from 'types/graphql';

import { colors } from 'services/theme';
import CustomModal from 'components/CustomModal';
import EditUserModalContent from './components/EditUserModalContent';

export const ALL_USERS = gql`
  query AllUsers {
    allUsers {
      id
      email
      phone
      name {
        first
        last
      }
      distributor {
        id
      }
    }
  }
`;

type Row = {
  name: string;
  id: string;
  email: string;
  phone: string;
};

const useStyles = makeStyles({
  outer: {
    padding: 24,
    backgroundColor: colors.background.light,
  },
  table: {
    minWidth: 650,
  },
});

const Users: React.FC = () => {
  const classes = useStyles();
  const [selectedRow, setSelectedRow] = React.useState<Row>();
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  // this will clear out the snackbar
  // auto-hide not working when success persisted as true
  React.useEffect(() => {
    const timer = setTimeout(() => setSuccess(false), 6000);
    return () => {
      clearTimeout(timer);
    };
  });

  const { data, loading, error } = useQuery(ALL_USERS);
  if (loading) return <Loading />;
  if (error) return <GenericError />;
  if (!data) return <div>No data... </div>;

  const { allUsers } = data;

  const createData = (
    name: string,
    id: string,
    email: string,
    phone: string
  ) => {
    return { name, id, email, phone };
  };

  const rows: Row[] = [];

  allUsers.map((user: AllUsersQuery['allUsers'][0]) => {
    const userFirstName = user.name && user.name.first ? user.name.first : '';
    const userLastName = user.name && user.name.last ? user.name.last : '';
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const userName = userFirstName.toString() + ' ' + userLastName.toString();
    const userId = user.id;
    const userEmail = user.email ? user.email : '';
    const userPhone = user.phone ? user.phone : '';

    rows.push(createData(userName, userId, userEmail, userPhone));
  });

  const handleEdit = (row: Row) => {
    setSelectedRow(row);
    setOpenEditModal(true);
  };

  return (
    <TableContainer className={classes.outer}>
      <div style={{ paddingBottom: 16 }}>
        <Chip color="primary" label={`Total Users: ${allUsers.length}`} />
      </div>
      <Table
        component={Paper}
        className={classes.table}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell align="right">ID</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Phone</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right">{row.email}</TableCell>
              <TableCell align="right">{row.phone}</TableCell>
              {/* Actions */}
              {/* <TableCell align="right">
                <Button onClick={() => handleEdit(row)}>Edit</Button>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <CustomModal
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        title="Edit user"
        addCloseButton={true}
      >
        {selectedRow && (
          <EditUserModalContent
            dealerId={selectedRow.id}
            setOpenEditModal={setOpenEditModal}
            setSuccess={setSuccess}
          />
        )}
      </CustomModal>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success">Successfully deleted dealer</Alert>
      </Snackbar>
    </TableContainer>
  );
};

export default Users;
