import * as React from 'react';

import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import { colors, FontClasses } from 'services/theme';

import CloseIcon from '@material-ui/icons/Close';

import { IsMobileConsumer } from 'providers/Responsive';

interface CustomModalProps {
  title?: string;
  subtitle?: string;
  content?: React.ReactChild | null;
  open: boolean;
  onClose: () => void;
  addCloseButton?: boolean;
  width?: string | number;
  height?: string | number;
  noPadding?: boolean;
  children?: React.ReactChild | null;
  minSize?: string | number;
  flex?: boolean;
  maxWidth?: number;
}

interface CustomModalState {}

class CustomModal extends React.Component<CustomModalProps, CustomModalState> {
  render() {
    const {
      title,
      subtitle,
      content,
      open,
      onClose,
      addCloseButton,
      width,
      height,
      noPadding,
      children,
      minSize,
      flex,
      maxWidth,
    } = this.props;

    return (
      <IsMobileConsumer>
        {({ isMobile }) => {
          const padding = isMobile ? 10 : 20;

          const desktopStyle: React.CSSProperties = {
            background: 'white',
            height: height,
            width: width,
            overflowY: 'auto',
            boxShadow: '1px 1px 16px -1px black',
            maxHeight: '90%',
            maxWidth: maxWidth ? maxWidth : '90%',
            minWidth: minSize,
            minHeight: minSize,
            display: flex ? 'flex' : 'block',
            flexDirection: 'column',
            borderRadius: 16,
          };

          const mobileStyle: React.CSSProperties = {
            margin: 'auto',
            background: 'white',
            height: '100%',
            width: '100%',
            overflowY: 'auto',
            display: flex ? 'flex' : 'block',
            flexDirection: 'column',
            borderRadius: 16,
          };

          const modalStyle = isMobile ? mobileStyle : desktopStyle;

          const modalHeader: React.CSSProperties = {
            display: 'flex',
            background: colors.lightGrey,
            margin: 0,
            padding,
            justifyContent: 'space-between',
            alignItems: 'center',
          };
          const modalHeaderContent: React.CSSProperties = {
            display: 'flex',
            flexDirection: 'column',
          };
          const modalHeaderTitle = {
            color: colors.text,
            margin: 0,
          };
          const modalHeaderSubtitle: React.CSSProperties = {
            color: colors.textLight,
            margin: 0,
          };

          const contentStyle = {
            padding: noPadding ? 0 : padding,
            display: flex ? 'flex' : 'block',
            flex: '1 1 auto',
          };
          const closeButtonDisplay = addCloseButton ? 'block' : 'none';
          return (
            <Modal
              open={open}
              onClose={onClose}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={modalStyle}>
                {(title || subtitle || addCloseButton) && (
                  <div className={FontClasses.title} style={modalHeader}>
                    <div style={modalHeaderContent}>
                      <span
                        className={FontClasses.title}
                        style={modalHeaderTitle}
                      >
                        {title}
                      </span>
                      <span
                        className={FontClasses.subtitle}
                        style={modalHeaderSubtitle}
                      >
                        {subtitle}
                      </span>
                    </div>
                    <IconButton
                      style={{ display: closeButtonDisplay }}
                      onClick={onClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                )}
                <div style={contentStyle}>{content || children}</div>
              </div>
            </Modal>
          );
        }}
      </IsMobileConsumer>
    );
  }
}

export default CustomModal;
